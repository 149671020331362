import * as R from 'ramda';
import { EModuleTypes } from '../definitions';

// So we can have better syntax in the components
const promiseWrapper = (fn) => (item, check, question) => new Promise((resolve, reject) => {
  const warning = fn(item, check, question);

  // eslint-disable-next-line prefer-promise-reject-errors
  if (warning) return reject({ text: warning });

  resolve();
});

const screen = (item, isQuestion, question) => {
  // console.log('debug item', item, isQuestion, question);
  if (!item)
    return 'learning:could_not_to_publish';

  if (!item.components || item.components.length === 0)
    return 'learning:warning_no_components';

  if (isQuestion) {
    if (!question?.parameters?.text || question.parameters.text.length <= 0)
      return 'learning:warning_no_question';
    if (question.parameters.options.length < 2)
      return 'learning:warning_not_enough_options';
    if (R.filter(R.propEq('correct', true), question.parameters.options).length === 0)
      return 'learning:warning_no_correct_option';
  }
};

export const screenValidator = promiseWrapper(screen);

export const moduleValidator = promiseWrapper((item) => {
  if (!item)
    return 'learning:could_not_to_publish';

  if (!item.screens || item.screens.length <= 0) {
    return 'learning:warning_no_screens';
  }

  //  Check if any of the questions is published or
  // if the publishScreens option is set to true
  // (this will publish all screens when the API endpoint is called).
  const hasPublishedQuestion = item.screens.find(
    ({ question, published }) => !!question && (published || item.publishScreens)
  );
  if (item.type === EModuleTypes.QUIZ && !hasPublishedQuestion) {
    return 'learning:warning_no_questions_in_quiz';
  }

  if (!item.name)
    return 'learning:warning_module_name';
});
