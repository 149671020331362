import * as R from 'ramda';
import Api from '../../../common/services/api';
import { LEARNING_UPDATE_COURSE } from './index';

const whitelist = [
  'name', 'description', 'published', 'sections', 'settings',
  'header_image_url', 'publish_all_modules'
];

export default (id, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(whitelist, values);

  const url = `/v2/organisations/${selected.id}/courses/${id}`;
  const { data } = await Api.put(url, payload);

  data.sections = data.sections.map((section) => ({
    ...R.omit(['modules'], section),
    module_ids: R.pluck('id', section.modules),
  }), []);

  return dispatch({
    type: LEARNING_UPDATE_COURSE,
    item: data,
  });
};
