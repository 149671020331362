import React, { memo } from 'react';
import Confirm from '@common/components/confirm-button';
import { useTranslation } from 'react-i18next';

type RemoveItemOverlayProps = {
  children: JSX.Element | JSX.Element[];
  onConfirm: (confirmed: boolean) => void;
};

const RemoveItemOverlay = memo(({
  children,
  onConfirm
}: RemoveItemOverlayProps) => {
  const { t } = useTranslation();
  return (
    <div className="ScreensOverview__Item__Overlay__Remove">
      <Confirm
        title={t('learning:screens_overview_item_confirm_remove')}
        onConfirm={onConfirm}
      >
        { children }
      </Confirm>
    </div>
  );
});

export default RemoveItemOverlay;
