import React, { useCallback, useEffect, useState } from 'react';
import { FormState, InjectedFormProps, reduxForm } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { Row, Group } from '@common/components/form';
import { AudienceInput } from '@common/components/form/audience';
import * as alert from '@common/services/alert';
import Modal from '@common/components/modal';
import { Button } from '@common/components/button';
import FilterItem from '../../components/filter-item';
import CoursePhasing from '@modules/learning/components/course-phasing/course-phasing';

import {
  AudienceDictionary, EPredicateFields, ESearchPredicateTypes,
  predicatesToPayload, useSimplePredicatesList,
} from '@common/utils/predicates';
import updateCourse from '../../actions/update-course';

import { Course } from '@modules/learning/types/objects';
import { StoreState } from '@common/types/store';
import { EPlanPackageConfig } from '@common/definitions';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';

type FormData = {
  phased: boolean;
  settings: {
    visible_after_days_invited: number | null;
    visibility: {
      predicate_type: string;
      predicates: Array<Object>;
    };
  }
};

type Props = {
  course: Course,
};

type OwnProps = InjectedFormProps<FormData> & ConnectedProps<typeof reduxConnector> & Props & {
  data: FormState;
};

type ShowModalState = {
  audience: boolean;
  phasing: boolean;
};

const LearningOnboardingForm = ({
  course, data, submitting, initialize, handleSubmit,
}: OwnProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<ShowModalState>({
    audience: false,
    phasing: false,
  });
  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.ACADEMY_AUDIENCE_SELECTOR,
  });

  useEffect(() => {
    initialize({
      phased: !!course.settings.visible_after_days_invited && course.settings.visible_after_days_invited > 0,
      settings: {
        visible_after_days_invited: course.settings.visible_after_days_invited,
        visibility: {
          predicate_type: course.settings.visibility.predicate_type,
          predicates: course.settings.visibility.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
        },
      },
    });
  }, [showModal]);

  const onSubmit = useCallback(async (values, dispatch) => {
    try {
      await dispatch(updateCourse(course.id, {
        settings: {
          visible_after_days_invited: values.phased ? values.settings.visible_after_days_invited : null,
          visibility: {
            predicate_type: values.settings.visibility.predicate_type,
            predicates: predicatesToPayload(values.settings.visibility.predicates),
          },
        },
      }));

      alert.success(t('learning:form_onboarding_saved'));
      setShowModal({ audience: false, phasing: false });
    } catch (response: any) {
      return alert.forStatus(response.status_code, {
        warning: t('learning:form_onboarding_warning_saving'),
        error: t('learning:form_onboarding_error_saving'),
      });
    }
  }, [course, updateCourse, setShowModal, t]);

  return (
    <>
      <Modal
        list
        title={t('learning:form_onboarding_phasing_modal_title')}
        show={showModal.phasing}
        onShow={() => setShowModal((current) => ({ ...current, phasing: true }))}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        content={(
          <CoursePhasing phased={data.values && data.values.phased} />
        )}
        footer={(
          <Button buttonType="submit" type="primary" isLoading={submitting}>
            <Trans i18nKey="learning:form_onboarding_save" />
          </Button>
        )}
      >
        <FilterItem icon="calendar_today" placeholder={t('learning:form_onboarding_phasing')}>
          {course && course.settings.visible_after_days_invited && course.settings.visible_after_days_invited > 0
            && t('learning:form_onboarding_days_after_invitation', { count: course.settings.visible_after_days_invited })}
        </FilterItem>
      </Modal>
      <Modal
        list
        size="large"
        title={t('learning:form_onboarding_visibility_modal_title')}
        show={showModal.audience}
        onShow={() => setShowModal((current) => ({ ...current, audience: true }))}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        content={(
          <Row>
            <Group>
              <AudienceInput
                name="settings.visibility"
                {...planPackageAudienceHandlers}
                enabledFilters={[
                  EPredicateFields.USER,
                  EPredicateFields.LANGUAGE,
                  EPredicateFields.NETWORK,
                  EPredicateFields.FUNCTION,
                ]}
              />
            </Group>
          </Row>
        )}
        footer={(
          <Button buttonType="submit" type="primary" isLoading={submitting}>
            <Trans i18nKey="learning:form_onboarding_save" />
          </Button>
        )}
      >
        <FilterItem icon="visibility_off" placeholder={t('learning:course_preview_visibility_everyone')}>
          {course && useSimplePredicatesList(course.filters as AudienceDictionary)}
        </FilterItem>
      </Modal>
    </>
  );
};

const reduxConnector = connect((state: StoreState, props: InjectedFormProps<FormData>) => ({
  data: state.form[props.form] || { values: {} },
}));

export default reduxForm<FormData, Props>({
  form: 'onboarding-course',
  initialValues: {
    phased: false,
    settings: {
      visible_after_days_invited: 1,
      visibility: {
        predicate_type: ESearchPredicateTypes.MATCH_ALL,
        predicates: [],
      },
    },
  },
})(reduxConnector(LearningOnboardingForm));
