import { useMemo } from 'react';

import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig, EPredicateFields } from '@common/definitions';

type Props = {
  packageIdAddFilters?: `${EPlanPackageConfig}`;
  packageIdDaysInService?: `${EPlanPackageConfig}`;
};

export const usePlanPackageAudienceHandlers = ({
  packageIdAddFilters, packageIdDaysInService,
}: Props) => {
  const {
    isAvailable: shouldAllowAddingFilters,
    showUpgradeModal: showFilterUpgradeModal,
  } = useIsAvailableInPlanPackage(packageIdAddFilters);

  const {
    isAvailable: canAddDaysInServiceFilter,
    showUpgradeModal: showDaysInServiceUpgradeModal,
  } = useIsAvailableInPlanPackage(packageIdDaysInService);

  const audienceHandlers = useMemo(() => ({
    onAddFilterClick: () => {
      if (!shouldAllowAddingFilters) showFilterUpgradeModal();
      return shouldAllowAddingFilters;
    },
    onFilterSelect: (type: `${EPredicateFields}`) => {
      const shouldAllowAddingDaysInServiceFilter = canAddDaysInServiceFilter || type !== EPredicateFields.DAYS_IN_SERVICE;
      if (!shouldAllowAddingDaysInServiceFilter) showDaysInServiceUpgradeModal();
      return shouldAllowAddingDaysInServiceFilter;
    },
  }), [shouldAllowAddingFilters, canAddDaysInServiceFilter, showDaysInServiceUpgradeModal, showFilterUpgradeModal]);

  return audienceHandlers;
};
