import * as R from 'ramda';
import Api from '../../../common/services/api';

export const ACADEMY_ADD_SCREEN = 'academy/ADD_SCREEN';

export const formatScreenToAdd = (screen) => {
  return {
    ...screen,
    component_ids: R.pluck('id', screen.components)
  };
};

export default (moduleId, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(['index'], values);
  const { data: screen } = await Api
    .post(`/v2/organisations/${selected.id}/modules/${moduleId}/screens`, payload);

  return dispatch({
    type: ACADEMY_ADD_SCREEN,
    moduleId,
    screen: formatScreenToAdd(screen),
  });
};
