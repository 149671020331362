/* eslint-disable new-cap */

import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Button } from '../../../../common/components/button';
import Permission from '../../../../common/components/permission';
import Icon from '../../../../common/components/icon';
import ScreenPreview, { EScales } from '../screen-preview';
import StatusSelector from '../status-selector';
import Status from '../status';
import * as validation from '../../utils/validation';
import * as screenSelector from '../../selectors/screen';
import ScreenDropdown from './screen-dropdown';
import RemoveItemOverlay from './remove-item-overlay';

const dragType = 'SCREEN_ITEM';

const target = {
  hover: (dropTarget, monitor) => {
    const item = monitor.getItem();
    const hoverIndex = dropTarget.index;

    if (item.id === dropTarget.item.id) return;

    dropTarget.onDrag(item.id, hoverIndex);
  },
  drop: (dropTarget) => {
    dropTarget.onDrop();
  }
};

const source = {
  beginDrag: (props) => ({
    id: props.item.id,
    index: props.index,
  }),
};

const dropTarget = DropTarget(dragType, target, (connector) => ({
  targetWrapper: connector.dropTarget(),
}));

const dragSource = DragSource(dragType, source, (connector, monitor) => ({
  sourceWrapper: connector.dragSource(),
  isDragging: monitor.isDragging(),
}));

class ScreensOverviewItem extends React.Component {
  static defaultProps = {
    resolution: { x: 350, y: 450 },
  };

  constructor() {
    super();

    this.handleOpen = this.handleOpen.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleUpdatePublished = this.handleUpdatePublished.bind(this);
  }

  static props;

  handleOpen() {
    this.props.onOpen(this.props.item.id);
  }

  handleRemove() {
    this.props.onRemove(this.props.item.id);
  }

  handleUpdatePublished(value) {
    return this.props.onUpdate(this.props.item.id, { published: value });
  }

  render() {
    const {
      item, coursePermissions, resolution, isDragging, targetWrapper,
      sourceWrapper, t, moduleId, organisationId, handleDuplicateScreen,
      ...props
    } = this.props;

    // console.log("debug ScreensOverviewItem this.props", this.props);

    const className = ['ScreensOverview__Item'];
    if (this.props.onOpen) className.push('ScreensOverview__Item--clickable');

    return targetWrapper(sourceWrapper(
      <div className={className.join(' ')} style={{ opacity: isDragging ? 0 : 1 }}>
        <div className="ScreensOverview__Item__Overlay">
          <RemoveItemOverlay onConfirm={this.handleRemove}>
            <Icon type="delete" />
          </RemoveItemOverlay>
          <div
            className="ScreensOverview__Item__Overlay__Inner"
            style={{
              width: resolution.x * EScales.xsmall,
              height: resolution.y * EScales.xsmall,
            }}
          >
            <Button type="inverted" onClick={this.handleOpen}><Trans i18nKey="learning:screens_overview_item_open" /></Button>
          </div>
        </div>

        <ScreenPreview
          {...R.omit(['tReady', 'i18n'], props)}
          item={item}
          screenId={item.id}
          resolution={resolution}
        />

        <div className="ScreensOverview__Item__Footer">
          <Permission name={coursePermissions.update}>
            <StatusSelector
              value={item.published}
              onChange={this.handleUpdatePublished}
              validate={() => validation.screenValidator(item)}
            />
          </Permission>
          <Permission name={coursePermissions.update} fallback>
            <Status published={item.published} />
          </Permission>
          <ScreenDropdown
            organisationId={organisationId}
            moduleId={moduleId}
            screen={item}
            handleDuplicateScreen={handleDuplicateScreen}
            onRemove={this.handleRemove}
          />
        </div>
      </div>,
    ));
  }
}

const mapStateToProps = () => {
  const selector = screenSelector.item();

  return (state, { item }) => ({
    item: selector(state, item.id),
  });
};

export default withTranslation()(dropTarget(dragSource(connect(mapStateToProps)(ScreensOverviewItem))));
