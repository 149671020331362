import * as React from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import * as R from 'ramda';
import dndManager from '@common/utils/dnd-manager';
import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import * as Alert from '../../../../common/services/alert';
import Permission from '../../../../common/components/permission';
import Container from '../../../../common/components/container';
import Overview from '../../../../common/components/overview';
import PhonePreview from '../../components/phone-preview';
import ComponentsList from '../../components/components-list';
import ScreenForm from '../../forms/screen';
import AddComponentForm from '../../forms/component/add';
import QuestionForm from '../../forms/question';
import * as moduleSelector from '../../selectors/module';
import * as courseSelector from '../../selectors/courses';
import * as draftSelector from '../../selectors/draft';
import { EPermissions } from '../../../../common/definitions';
import { EModuleTypes, ECourseTypes } from '../../definitions';

require('../../styles.scss');

class AcademyScreen extends React.Component {

  constructor(props) {
    super();

    this.state = {
      highlightedComponent: null,
    };

    this.setComponentsListReference = (ref) => (this.componentsListRef = ref);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleOpenComponent = this.handleOpenComponent.bind(this);
    this.handleDeleteComponent = (id) => props.removeComponent(id, props.match.params.screenId);
    this.handleHoverComponent = (id) => this.setState({ highlightedComponent: id || null });
  }

  async componentDidMount() {
    const { history, course, match: { params }, t } = this.props;

    try {
      await this.props.createDraft(params.screenId, params.moduleId);

      if (!course) this.props.fetchCourse(params.courseId);
    } catch (err) {
      if (err.message === 'no_screen_found') {
        Alert.warning(t('learning:screen_not_found'));

        return history.push(`/admin/learning/courses/${params.courseId}/modules/${params.moduleId}`);
      }

      if (err.status_code === 404) {
        Alert.warning(t('learning:screen_module_not_found'));
      } else {
        Alert.error(t('learning:screen_module_error_fetching'));
      }

      history.push(`/admin/learning/courses/${params.courseId}`);
    }
  }


  componentWillUnmount() {
    this.props.resetDraft();
  }

  static props;
  static pageTitle = 'Scherm';

  componentsListRef;

  handleDrag(componentId, index) {
    const { screen } = this.props;

    if (!screen) return;

    const order = R.pipe(R.sortBy(R.prop('index')), R.pluck('id'))(screen.components);

    const newOrder = R.insert(
      index,
      componentId,
      R.remove(R.indexOf(componentId, order), 1, order),
    );

    this.props.updateComponentsOrder(screen.id, newOrder);
  }

  async handleDelete() {
    const { screen, module: moduleItem, onDelete, history } = this.props;

    if (screen && moduleItem) {
      await onDelete(screen.id, moduleItem.id);

      history.goBack();
    }
  }

  handleBack() {
    const { history, match: { params } } = this.props;
    history.push(`/admin/learning/courses/${params.courseId}/modules/${params.moduleId}`);
  }

  handleOpenComponent(component) {
    if (this.componentsListRef) {
      this.componentsListRef.handleEditComponent(component.id);
    }
  }

  render() {
    const {
      module: moduleItem, course, screen, match: { params }, t,
    } = this.props;

    const name = screen?.question ? t('learning:form_question_question_label') : t('learning:screen_title_screen');

    return (
      <Container name="Screen">
        <TopNavigationBar
          className="AcademyScreen__TopNavigation"
          breadcrumbs={[
            { name: t('learning:breadcrumb_learning_environment') },
            course &&
            course.type === ECourseTypes.ONBOARDING &&
            { name: t('learning:breadcrumb_onboarding'), path: '/admin/learning/onboarding' },
            course &&
            course.type === ECourseTypes.ACADEMY &&
            { name: t('learning:breadcrumb_academy'), path: '/admin/learning/academy' },
            course && { name: course.name, path: `/admin/learning/courses/${course.id}` },
            course &&
            moduleItem && { name: moduleItem.name, path: `/admin/learning/courses/${course.id}/modules/${moduleItem.id}` },
            course && moduleItem && screen && { name },
          ]}
          title={name}
          action={(
            <Permission name={course && (course.type === ECourseTypes.ACADEMY ?
              EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE :
              EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE)}
            >
              <ScreenForm
                module={moduleItem}
                screen={screen}
                onDelete={this.handleDelete}
                onBack={this.handleBack}
              />
            </Permission>
          )}
        />
        <Container.Content>
          <div className="Editor">
            <div className="Editor__Preview">
              <div className="Editor__Preview__Container">
                {params.screenId && (
                  <PhonePreview
                    isLoading={!screen}
                    screenIDs={[params.screenId]}
                    navbarTitle={moduleItem ? moduleItem.name : ''}
                    showBackButton={moduleItem && moduleItem.type !== EModuleTypes.QUIZ}
                    progress={moduleItem && {
                      total: moduleItem.screens ? moduleItem.screens.length : 0,
                      current: screen && moduleItem ? R.findIndex(R.propEq('id', screen.id), moduleItem.screens) : 0,
                    }}
                    highlight={this.state.highlightedComponent}
                    onOpenComponent={this.handleOpenComponent}
                  />
                )}
              </div>
            </div>
          </div>

          <Permission name={course && (course.type === ECourseTypes.ACADEMY ?
            EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE :
            EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE)}
          >
            <Overview>
              <Overview.Content>
                <div className="Editor__Components">
                  <DndProvider manager={dndManager}>
                    <ComponentsList
                      ref={this.setComponentsListReference}
                      items={screen ? screen.components : []}
                      onDrag={this.handleDrag}
                      onDelete={this.handleDeleteComponent}
                      onHover={this.handleHoverComponent}
                    />
                  </DndProvider>

                  <AddComponentForm
                    id={screen && screen.id}
                    type="screen"
                    components={(screen && screen.components) || []}
                  />

                  {moduleItem && moduleItem.type === EModuleTypes.QUIZ && screen && screen.question && (
                    <>
                      <div style={{ height: 40 }} />
                      <DndProvider manager={dndManager}>
                        <QuestionForm form={`component-${screen.question.id}`} screen={screen} />
                      </DndProvider>
                    </>
                  )}
                </div>
              </Overview.Content>
            </Overview>
          </Permission>
        </Container.Content>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  course: courseSelector.item(state, props.match.params.courseId),
  module: moduleSelector.item(state, props.match.params.moduleId),
  screen: draftSelector.screen(state, props.match.params.screenId),
});

const mapDispatchToProps = {
  fetchCourse: require('../../actions/fetch-course').default,
  createDraft: require('../../actions/create-screen-draft').default,
  onDelete: require('../../actions/remove-screen').default,
  removeComponent: require('../../actions/remove-component').default,
  updateComponentsOrder: require('../../actions/update-components-order').default,
  resetDraft: require('../../actions/reset-draft').default,
  getState: () => (_, getState) => getState(),
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AcademyScreen));
