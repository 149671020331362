import * as R from 'ramda';
import Api from '../../../common/services/api';
import { ACADEMY_ADD_SCREEN } from './add-screen';
import { EComponentTypes } from '../definitions';

export default (moduleId, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(['index'], values);
  payload.question = {
    points: 0,
    text: null,
    options: [],
  };

  const { data: screen } = await Api
    .post(`/v2/organisations/${selected.id}/modules/${moduleId}/screens`, payload);

  const question = R.find(R.propEq('type', EComponentTypes.ELEARNING_QUESTION), screen.components);

  screen.component_ids = R.pluck('id', screen.components);

  screen.question = question;
  screen.question_id = question ? question.id : null;

  return dispatch({
    type: ACADEMY_ADD_SCREEN,
    moduleId,
    screen,
  });
};
