import React, { memo, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import AudienceModal from '@common/components/form/audience/audience-modal';
import { PredicatesFilterValue } from '@common/components/predicates-filter';
import { EPredicateFields } from '@common/definitions';
import { AlertService } from '@common/services/alert';
import Api from '@common/services/api';
import { ApiLearningPathResponse } from '@modules/learning/types/learning-paths';

type LearningPathAudienceModalProps = {
  orgId: string;
  learningPath: ApiLearningPathResponse;
  onHide: (updatedPath?: ApiLearningPathResponse) => void;
};

type FormData = {
  audience: PredicatesFilterValue;
};

const ENABLED_FILTERS = [
  EPredicateFields.USER,
  EPredicateFields.FUNCTION,
  EPredicateFields.NETWORK,
  EPredicateFields.DAYS_IN_SERVICE,
  EPredicateFields.LANGUAGE
];

const LearningPathAudienceModal = memo(({
  orgId,
  learningPath,
  onHide
}: LearningPathAudienceModalProps) => {

  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const pathId = learningPath.data.id;
  const onSubmit = useCallback(async (data: FormData) => {
    try {
      setSubmitting(true);
      const url = `/v2/organisations/${orgId}/learning-paths/${pathId}`;
      const res = await Api.put<ApiLearningPathResponse>(url, {
        audience: {
          predicates: data.audience.predicates.map((p) => _.omit(p, ['id'])),
          predicate_type: data.audience.predicate_type
        }
      });
      AlertService.success(t('core:changes_saved'));
      onHide(res);
    } catch (error) {
      AlertService.error(t('common:something_went_wrong'));
      throw error;
    } finally {
      setSubmitting(false);
    }
  }, [orgId, pathId, setSubmitting, t, onHide]);

  const initialValues = useMemo(() => {
    return {
      audience: learningPath.data.audience
    };
  }, [learningPath]);

  const argumentlessOnHide = useCallback(() => onHide(), [onHide]);

  return (
    <AudienceModal
      // @ts-expect-error
      onSubmit={onSubmit}
      onHide={argumentlessOnHide}
      show
      enabledFilters={ENABLED_FILTERS}
      submitting={submitting}
      initialValues={initialValues}
      title={t('learning:publish_academy_select_target_audience')}
    />
  );
});

export default LearningPathAudienceModal;
