import React, { ChangeEvent } from 'react';
import { EventOrValueHandler } from 'redux-form';

import Icon from '@common/components/icon';
import { combineClassNames } from '@common/utils/combineClassNames';
import Tooltip from '../tooltip';

export type RadioButtonValue = string | boolean;

export type RadioButtonOwnProps = {
  className?: string;
  label?: string;
  description?: string;
  value: RadioButtonValue;
  currentValue: RadioButtonValue;
  onChange: EventOrValueHandler<ChangeEvent<RadioButtonValue>>;
};

export const RadioButton = ({
  className, label, description, onChange, value, currentValue,
}: RadioButtonOwnProps) => (
  <>
    <Icon
      className={combineClassNames('DefaultRadioButton', className)}
      type={`radio_button_${value === currentValue ? '' : 'un'}checked`}
      onClick={() => onChange(value)}
    />
    {label &&
    <p
      className="DefaultRadioButton__Label"
      onClick={() => onChange(value)}
    >
        {label}
        {description && <Tooltip title={description} fullTitle><Icon type="info" /></Tooltip>}
    </p>}
  </>
);
