import * as R from 'ramda';
import memoize from '../../../common/utils/memoize';
import * as modulesReducer from '../reducers/modules';

const getModule = (state, id) => state.academy.modules.items[id];

const getAllModules = (state) => state.academy.modules.items;

export const list = memoize.createSelector(
  [getAllModules],
  (state, modules) => R.values(modules),
);

export const item = memoize.createSelector(
  [getModule],
  (state, course) => (course ? modulesReducer.findById(state, course.id) : undefined),
);

export const getModuleItem = item;
