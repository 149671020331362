import * as React from 'react';
import Icon from '@common/components/icon';
import Tooltip from '@common/components/tooltip';

const LearningFilterItem = ({ children, icon, placeholder, ...props }) => {
  const [useTooltip, setUseTooltip] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const { current: el } = ref;

    if (el && el.offsetWidth < el.scrollWidth - 2) setUseTooltip(true);
  });

  const content = (
    <div
      ref={ref}
      className={`FilterItem${children ? ' FilterItem--active' : ''}`}
      // $FlowFixMe
      {...props}
    >
      <Icon type={icon} />
      {children || placeholder}
    </div>
  );

  return useTooltip ? <Tooltip title={children || placeholder}>{content}</Tooltip> : content;
};

export default LearningFilterItem;
