import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Alert from '@common/components/alert';
import { Row, Group, CheckboxInput } from '@common/components/form';
import { Message } from '@common/components/form/message';
import NumberInput from '@common/components/form/inputs/number';
import Label from '@common/components/form/label';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';

type Props = {
  phased: boolean;
};

const CoursePhasing = ({ phased }: Props) => {
  const { t } = useTranslation();
  const [warning, setWarning] = useState<string | undefined>(undefined);

  const {
    isAvailable: canAddPhasing, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_PHASING);

  return (
    <>
      <Alert type="info">
        <Trans i18nKey="learning:form_onboarding_phasing_description" />
      </Alert>
      <Row>
        <CheckboxInput
          name="phased"
          label={t('learning:form_onboarding_phasing_label')}
          onChange={canAddPhasing || phased ? undefined : showUpgradeModal}
        />
      </Row>
      {phased && (
        <Row>
          <Group>
            <Label
              text={t('learning:form_onboarding_phasing_days_after_invited')}
              forInput="settings.visible_after_days_invited"
            />
            <Row style={{ lineHeight: '36px', marginBottom: '4px' }}>
              <div style={{ width: 90 }}>
                <NumberInput
                  name="settings.visible_after_days_invited"
                  min={1}
                  max={365}
                  disabled={!canAddPhasing}
                  onWarning={(value) => {
                    if (value !== warning) {
                      setWarning(value);
                    }
                  }}
                />
              </div>
              <div style={{ marginLeft: 16 }}>
                <Trans i18nKey="learning:form_onboarding_phasing_days" />
              </div>
            </Row>
            <Row>
              <Message warning={warning} min={1} max={365} />
            </Row>
          </Group>
        </Row>
      )}
    </>
  );
};

export default CoursePhasing;
