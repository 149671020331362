import * as R from 'ramda';
import Api from '../../../common/services/api';

export const ACADEMY_UPDATE_MODULE = 'academy/UPDPATE_MODULE';

const whitelist = [
  'name', 'description', 'published', 'duration', 'pass_rate', 'max_points',
  'show_correct_answers', 'publishAllScreens'
];

export default (id, values, courseId, publishScreens) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = {
    ...R.pick(whitelist, R.reject(R.isNil, values)),
    publish_all_screens: !!publishScreens
  };
  const url = `/v2/organisations/${selected.id}/modules/${id}`;
  const { data: module } = await Api.put(url, payload);

  return dispatch({
    type: ACADEMY_UPDATE_MODULE,
    item: R.omit(['screens'], module),
    courseId,
  });
};
