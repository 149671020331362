import * as R from 'ramda';
import * as draftReducer from '../reducers/draft';
import { EComponentTypes } from '../definitions';
import updateScreen from './update-screen';
import createScreenDraft from './create-screen-draft';

const whitelist = ['title', 'published'];

const format = (data) => {
  return R.addIndex(R.map)(
    (option, index) => R.assoc('index', index, R.omit(['row_type', 'local', option.local ? 'id' : null], option)),
    data,
  );
};

export default (id, moduleId, values) => async (dispatch, getState) => {
  const state = getState();

  // Get everything from draft
  const draft = draftReducer.getScreen(state, id, true);

  const payload = R.pick(whitelist, values);

  // Find question in the components array
  const question = R.find(R.propEq('type', EComponentTypes.ELEARNING_QUESTION), draft.components);

  // If question exists add question data to payload
  if (question) {
    question.parameters.options = format(question.parameters.options);

    payload.question = R.omit(['multiple_choice'], question.parameters);

    payload.question.correct_feedback = format(question.parameters.correct_feedback);
    payload.question.incorrect_feedback = format(question.parameters.incorrect_feedback);

    payload.question.hint = payload.question.hint || null;
    payload.question.points = values.points;
  }

  // Add components to payload (excluding question component)
  payload.components = R.pipe(
    R.reject(R.either(R.isNil, R.propEq('type', EComponentTypes.ELEARNING_QUESTION))),
    R.addIndex(R.map)((component, i) => R.pipe(
      R.pick([!component.local ? 'id' : null, 'index', 'type', 'parameters']),
      R.assoc('index', i),
    )(component)),
  )(draft.components);

  await dispatch(updateScreen(id, moduleId, payload));

  return dispatch(createScreenDraft(id));
};
