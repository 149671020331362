import * as R from 'ramda';
import Api from '../../../common/services/api';
import { EComponentTypes } from '../definitions';

export const ACADEMY_UPDATE_SCREEN = 'academy/UPDATE_SCREEN';

export default (id, moduleId, payload) => async (dispatch, getState) => {
  const state = getState();
  const { academy, organisation: { selected } } = state;

  const currentScreen = academy.screens.items[id];

  const { data: screen } = await Api.put(`/v2/organisations/${selected.id}/modules/${moduleId}/screens/${id}`, payload);

  const question = R.find(R.propEq('type', EComponentTypes.ELEARNING_QUESTION), screen.components);

  screen.component_ids = R.pluck('id', screen.components);

  screen.question = question;
  screen.question_id = question ? question.id : null;

  return dispatch({
    type: ACADEMY_UPDATE_SCREEN,
    screen,
    module_id: moduleId || screen.module_id,
    shouldIncrementScreenCount: currentScreen.published === false && screen.published === true,
    shouldDecrementScreenCount: currentScreen.published === true && screen.published === false,
  });
};
