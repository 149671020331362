import React, { memo, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import ToggleContent from '@common/components/toggle-content';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import Image from '@common/components/image';
import { AlertService } from '@common/services/alert';
import Api from '@common/services/api';
import { APIAcademyCourse } from '@modules/learning/types/objects';
import ModuleStatus from '../status';
import './course-list-item.scss';

type Source = {
  id: string;
};

const CourseListItem = memo(({
  item,
  onChangeOrder,
  onDrop,
  resetOrder,
  setCourses,
  orgId,
  pathId,
  courses
}: any) => {

  const [removing, setRemoving] = useState<boolean>(false);

  const [, dropRef] = useDrop({
    accept: ['course'],
    hover: (source: Source) => {
      if (source.id === item.id) return;

      onChangeOrder(source.id, item.id);
    },
    canDrop() {
      return !removing;
    }
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'course',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end(_, monitor) {
      const dropped = monitor.didDrop();
      if (dropped) {
        return onDrop();
      }
      resetOrder();
    },
    canDrag() {
      return !removing;
    }
  });

  const { t } = useTranslation();

  const itemId = item.id;
  const onRemove = useCallback(async () => {
    try {
      setRemoving(true);
      const newCourses = courses.filter((c: APIAcademyCourse) => c.id !== itemId);
      await Api.patch(
        `/v2/organisations/${orgId}/learning-paths/${pathId}/courses`,
        { courses: newCourses.map((c: APIAcademyCourse) => c.id) }
      );
      setCourses(newCourses);
    } catch (error) {
      AlertService.error(t('common:something_went_wrong'));
      throw error;
    } finally {
      setRemoving(false);
    }
  }, [setCourses, orgId, pathId, itemId, setRemoving, courses]);

  const opacity = (() => {
    if (removing) return 0.5;
    if (isDragging) return 0;
    return 1;
  })();

  const { url } = useRouteMatch();

  return (
    <div
      className="CourseListItem"
      ref={dropRef}
      style={{ opacity }}
    >
      { /* @ts-expect-error */ }
      <ToggleContent
        disabled
        title={(
          <Link to={`/admin/learning/courses/${item.id}?backUrl=${encodeURIComponent(url)}`}>
            { item.name }
          </Link>
        )}
        image={item.header_image_url
          ? <Image size={32} alt={item.name} src={item.header_image_url} />
          : <div className="TopNavigationBar__Row__Info__Image"><Icon type="image" /></div>}
        handleRef={dragRef}
        previewRef={previewRef}
        className="OnboardingToggle"
        action={
          <>
            <div className="statusIndicator">
              <ModuleStatus small published={item.published} />
              { item.published ? t('common:live') : t('common:draft') }
            </div>
            <Dropdown
              alignRight
              toggle={<Icon type="more_vert" />}
            >
              <ConfirmButton
                danger
                title={t('learning:remove_course_from_learning_path')}
                onConfirm={onRemove}
                confirmText={t('common:remove')}
              >
                <Dropdown.Item danger>
                  { t('common:remove') }
                </Dropdown.Item>
              </ConfirmButton>
            </Dropdown>
          </>
        }
      />
    </div>
  );
});

export default CourseListItem;
