import * as R from 'ramda';
import memoize from '../../../common/utils/memoize';
import * as coursesReducer from '../reducers/courses';
import { ECourseTypes } from '../definitions';

const getCourses = (state) => state.learning.courses.items;
const getCourse = (state, id) => state.learning.courses.items[id];

export const all = memoize.createSelector(
  [getCourses],
  (state, courses) => R.values(courses).map(({ id }) => coursesReducer.findById(state, id)),
);

const filterByType = (type) => (state, courses) => R.pipe(
  R.values,
  R.filter(R.propEq('type', type)),
  R.map(({ id }) => coursesReducer.findById(state, id)),
  R.sortBy(R.prop('index')),
)(courses);

export const onboarding = memoize.createSelector(
  [getCourses],
  filterByType(ECourseTypes.ONBOARDING),
);

export const academy = memoize.createSelector(
  [getCourses],
  filterByType(ECourseTypes.ACADEMY),
);

export const item = memoize.createSelector(
  [getCourse],
  (state, course) => {
    if (!course) return undefined;

    return coursesReducer.findByIdWithModules(state, course.id);
  },
);
