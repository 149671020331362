import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';

import NumberInput from '@common/components/form/inputs/number';
import { Row, Group, Label } from '@common/components/form';
import { Message } from '@common/components/form/message';
import * as Alert from '@common/services/alert';
import Modal from '@common/components/modal';
import { Button } from '@common/components/button';
import { updateOnboardingSettings } from '../../actions';

class OnboardingSettingsForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      warning: null,
    };

    this.handleExit = () => props.initialize({ due_date_offset: this.props.organisation.settings.onboarding_due_date_offset });
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { organisation, initialize } = this.props;

    initialize({
      due_date_offset: organisation.settings.onboarding_due_date_offset,
    });
  }

  static props;
  modal;

  async handleSubmit(values, dispatch) {
    const { t } = this.props;

    try {
      await dispatch(updateOnboardingSettings({
        due_date_offset: values.due_date_offset,
      }));

      Alert.success(t('learning:form_onboarding_settings_edited'));
      if (this.modal) this.modal.handleClose();
    } catch (response) {
      return Alert.forStatus(response.status_code, {
        warning: t('learning:form_onboarding_settings_warning_saving'),
        error: t('learning:form_onboarding_settings_error_saving'),
      });
    }
  }

  render() {
    const { warning } = this.state;
    const {
      children, handleSubmit, invalid, submitting, t,
    } = this.props;

    return (
      <Modal
        onExited={this.handleExit}
        title={t('learning:form_onboarding_settings_modal_title')}
        content={(
          <Row>
            <Group>
              <Label
                forInput="due_date_offset"
                text={t('organisation:forms_organisation_onboarding_due_date')}
              />
              <small>{t('organisation:forms_organisation_onboarding_due_date_description')}</small>
              <div style={{ width: 90 }}>
                <NumberInput
                  name="due_date_offset"
                  min={0}
                  max={1000}
                  onWarning={(value) => {
                    if (value !== warning) {
                      this.setState({ warning: value });
                    }
                  }}
                />
              </div>
              <Message warning={warning} min={0} max={1000} />
            </Group>
          </Row>
        )}
        footer={(
          <Button
            type="primary"
            buttonType="submit"
            disabled={invalid}
            isLoading={submitting}
          >
            <Trans i18nKey="learning:form_onboarding_settings_save" />
          </Button>
        )}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(this.handleSubmit),
        }}
      >
        {children}
      </Modal>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'course',
  initialValues: {
    due_date_offset: 30,
  },
})(OnboardingSettingsForm));
