import * as R from 'ramda';
import Api from '../../../common/services/api';
import { LEARNING_CREATE_COURSE } from './index';

const whitelist = ['name', 'description', 'header_image_url'];

export default (type, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(whitelist, values);
  payload.type = type;

  const { data: course } = await Api.post(`/v2/organisations/${selected.id}/courses`, payload);

  return dispatch({
    type: LEARNING_CREATE_COURSE,
    item: course,
  });
};
