import * as R from 'ramda';

export const includesInQuery = R.curry((row, query, keysToSearch) => {
  if (!query) return true;

  const searchQuery = query.toLowerCase();

  const includedInQuery = (value) => R.toLower(value).includes(R.toLower(searchQuery));

  return R.pipe(
    R.pick(keysToSearch),
    R.map(includedInQuery),
    R.values,
    R.contains(true),
  )(row);
});
