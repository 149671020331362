import Api from '../../../common/services/api';

export const ACADEMY_REMOVE_MODULE = 'academy/REMOVE_MODULE';

export default (moduleId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v2/organisations/${selected.id}/modules/${moduleId}`);

  return dispatch({
    type: ACADEMY_REMOVE_MODULE,
    moduleId,
  });
};
