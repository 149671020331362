import React, { useCallback, useEffect, useState } from 'react';
import { FormState, InjectedFormProps, reduxForm } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';
import { Trans, TFunction, useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import * as R from 'ramda';

import { Row, Group, CheckboxInput } from '@common/components/form';
import { AudienceInput } from '@common/components/form/audience';
import * as alert from '@common/services/alert';
import Modal from '@common/components/modal';
import Alert from '@common/components/alert';
import { Button } from '@common/components/button';
import FilterItem from '../../components/filter-item';
import CourseDeadline from '../../components/course-deadline';

import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';
import updateCourse from '../../actions/update-course';

import {
  AudienceDictionary,
  EPredicateFields, ESearchPredicateTypes,
  predicatesToPayload, useSimplePredicatesList,
} from '@common/utils/predicates';
import { dateAndTimeToStr } from '@common/utils/date';
import { EPlanPackageConfig, EPredicateOperators } from '@common/definitions';
import { StoreState } from '@common/types/store';
import { Course } from '@modules/learning/types/objects';

type FormData = {
  has_deadline?: boolean;
  deadline_after_days_invited?: string;
  deadline?: {
    date: Moment;
    time: string;
  };
  settings: {
    visibility: {
      predicate_type: string;
      predicates: Object[];
    };
  }
};

type Props = {
  course: Course,
};

type OwnProps = InjectedFormProps<FormData> & ConnectedProps<typeof reduxConnector> & Props & {
  data: FormState;
};

type Payload = {
  deadline: string | null;
  deadline_after_days_invited: string | null;
  visibility: {
    predicate_type: string;
    predicates: {
      attribute: EPredicateFields | 'answers.value';
      comparison: EPredicateOperators;
      value: unknown;
    }[];
  }
};

type ShowModalState = {
  audience: boolean;
  deadline: boolean;
};

const getDeadline = (
  { settings: { deadline, deadline_after_days_invited } }: Course,
  t: TFunction,
) => {
  if (deadline) {
    return moment(deadline).format(`DD MMM YYYY [${t('learning:form_academy_at')}] HH:mm`);
  }
  if (deadline_after_days_invited) {
    return `${deadline_after_days_invited} ${t('common:days')}`;
  }
  return null;
};

const LearningAcademyForm = ({
  course, dirty, submitting, data, initialize, handleSubmit,
}: OwnProps) => {
  const { t } = useTranslation();
  const [deadlineDynamic, setDeadlineDynamic] = useState(false);
  const [showModal, setShowModal] = useState<ShowModalState>({
    audience: false,
    deadline: false,
  });
  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.ACADEMY_AUDIENCE_SELECTOR,
  });

  useEffect(() => {
    const deadline = moment(course.settings.deadline || undefined);
    const deadline_after_days_invited = course.settings.deadline_after_days_invited || '30';
    if (!course.settings.deadline) deadline.set({ hour: 9, minute: 0 });
    setDeadlineDynamic(!!course.settings.deadline_after_days_invited);

    initialize({
      has_deadline: !!course.settings.deadline || !!course.settings.deadline_after_days_invited,
      deadline: {
        date: deadline,
        time: deadline.format('HH:mm'),
      },
      deadline_after_days_invited,
      settings: {
        visibility: {
          predicate_type: course.settings.visibility.predicate_type,
          predicates: course.settings.visibility.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
        },
      },
    });
  }, [showModal]);

  const onSubmit = useCallback(async (values, dispatch) => {
    try {
      const settings: Payload = {
        deadline: null,
        deadline_after_days_invited: null,
        visibility: {
          predicate_type: values.settings.visibility.predicate_type,
          predicates: predicatesToPayload(values.settings.visibility.predicates),
        },
      };

      if (values.has_deadline) {
        if (deadlineDynamic) {
          settings.deadline_after_days_invited = values.deadline_after_days_invited;
        } else {
          settings.deadline = dateAndTimeToStr(values.deadline.time, values.deadline.date);
        }
      }

      await dispatch(updateCourse(course.id, { settings }));
      alert.success(t('learning:form_academy_deadline_has_been_saved'));
      setShowModal({ audience: false, deadline: false });
    } catch (response: any) {
      return alert.forStatus(response.status_code, {
        warning: t('learning:form_academy_warning_saving_deadline'),
        error: t('learning:form_academy_error_saving_deadline'),
      });
    }
  }, [course, deadlineDynamic, updateCourse, setShowModal, t]);

  return (
    <>
      <Modal
        list
        size="large"
        show={showModal.audience}
        onShow={() => setShowModal((current) => ({ ...current, audience: true }))}
        // onClose={() => reset()}
        title={t('learning:form_academy_target_audience_modal_title')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        content={(
          <Row>
            <Group>
              <AudienceInput
                name="settings.visibility"
                {...planPackageAudienceHandlers}
                enabledFilters={[
                  EPredicateFields.USER,
                  EPredicateFields.LANGUAGE,
                  EPredicateFields.NETWORK,
                  EPredicateFields.FUNCTION,
                  EPredicateFields.DAYS_IN_SERVICE,
                ]}
              />
            </Group>
          </Row>
        )}
        footer={(
          <Button buttonType="submit" type="primary" disabled={!dirty} isLoading={submitting}>
            <Trans i18nKey="learning:form_academy_save" />
          </Button>
        )}
      >
        <FilterItem icon="visibility__filled" placeholder={t('learning:course_preview_visibility_everyone')}>
          {useSimplePredicatesList(course.filters as AudienceDictionary)}
        </FilterItem>
      </Modal>
      <Modal
        list
        show={showModal.deadline}
        onShow={() => setShowModal((current) => ({ ...current, deadline: true }))}
        // onClose={() => reset()}
        title={t('learning:form_academy_deadline_modal_title')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        content={(
          <>
            <Alert type="info">
              <Trans i18nKey="learning:form_academy_deadline_description" />
            </Alert>
            <Row>
              <CheckboxInput
                name="has_deadline"
                label={t('learning:form_academy_deadline_label')}
              />
            </Row>
            {data.values && data.values.has_deadline && (
              <CourseDeadline
                deadlineDynamic={deadlineDynamic}
                setDeadlineDynamic={(value) => setDeadlineDynamic(value)}
              />
            )}
          </>
        )}
        footer={(
          <Button buttonType="submit" type="primary" isLoading={submitting}>
            <Trans i18nKey="learning:form_academy_save" />
          </Button>
        )}
      >
        <FilterItem icon="calendar_today__filled" placeholder={t('learning:form_academy_deadline_label')}>
          {getDeadline(course, t)}
        </FilterItem>
      </Modal>
    </>
  );
};

const reduxConnector = connect((state: StoreState, props: InjectedFormProps<FormData>) => ({
  data: state.form[props.form] || { values: {} },
}));

export default reduxForm<FormData, Props>({
  form: 'course-deadline',
  initialValues: {
    settings: {
      visibility: {
        predicate_type: ESearchPredicateTypes.MATCH_ALL,
        predicates: [],
      },
    },
  },
})(reduxConnector(LearningAcademyForm));
