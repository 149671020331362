import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';

import ToggleContent from '@common/components/toggle-content';
import Image from '@common/components/image';
import Permission from '@common/components/permission';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import Confirm from '@common/components/confirm-button';
import StatusSelector from '../status-selector';
import OnboardingForm from '../../forms/onboarding';

import { EPermissions } from '@common/definitions';
import type { Course } from '../../types/objects';

export type Props = {
  index: number;
  item: Course,
  onChangeOrder: (sourceId: string, targetId: string) => void,
  onDrop: () => Promise<void>,
  onOpen: (id: string) => void,
  onPublish: (id: string, publish: boolean) => void,
  onDelete: (id: string) => Promise<void>,
  onDuplicate: (id: string) => Promise<void>,
  onChangeCourseType: (id: string) => Promise<void>,
};

type Source = {
  id: string;
};

const LearningOnboardingItem = ({
  index,
  item,
  onChangeOrder,
  onDrop,
  onOpen,
  onPublish,
  onDelete,
  onDuplicate,
  onChangeCourseType,
}: Props) => {
  const { t } = useTranslation();

  const [, dropRef] = useDrop({
    accept: ['course'],
    hover: (source: Source) => {
      if (source.id === item.id) return;

      onChangeOrder(source.id, item.id);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'course',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleChangeStatus = (publish: boolean) => setTimeout(() => onPublish(item.id, publish), 0);

  return (
    <div ref={dropRef} style={{ opacity: isDragging ? 0 : 1, marginBottom: 12 }}>
      {/* @ts-expect-error */}
      <ToggleContent
        disabled
        image={item.header_image_url
          ? <Image size={32} alt={item.name} src={item.header_image_url} />
          : <div className="TopNavigationBar__Row__Info__Image"><Icon type="image" /></div>}
        title={(
          <a onClick={() => onOpen(item.id)} role="link">{item.name}</a>
        )}
        handleRef={dragRef}
        previewRef={previewRef}
        className="OnboardingToggle"
        action={(
          <>
            <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
              <OnboardingForm
                form={`onboarding-course/${item.id}`}
                course={item}
              />
              <>
                <div className="spacer" />
                <StatusSelector
                  // @ts-expect-error
                  value={!!item.published}
                  onChange={handleChangeStatus}
                />
              </>
            </Permission>
            <Permission
              name={[EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE, EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE]}
            >
              <>
                <div className="spacer" />
                <Dropdown
                  alignRight
                  id={`onboarding-item-${item.id}`}
                  toggle={<Icon type="more_vert" />}
                >
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE}>
                    <Dropdown.Item onClick={() => onDuplicate(item.id)}>
                      <Trans i18nKey="learning:onboarding_item_duplicate" />
                    </Dropdown.Item>
                  </Permission>
                  <Dropdown.Item onClick={() => void onChangeCourseType(item.id)}>
                    {t('learning:move_to_elearning')}
                  </Dropdown.Item>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE}>
                    <Confirm
                      title={t('learning:onboarding_item_confirm_remove_title')}
                      description={t('learning:onboarding_item_confirm_remove_description')}
                      onConfirm={() => onDelete(item.id)}
                    >
                      <Dropdown.Item danger><Trans i18nKey="learning:onboarding_item_remove" /></Dropdown.Item>
                    </Confirm>
                  </Permission>
                </Dropdown>
              </>
            </Permission>
          </>
        )}
      />
    </div>
  );
};

export default LearningOnboardingItem;
