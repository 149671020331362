import React, { memo } from 'react';
import { reduxForm } from 'redux-form';
import { AudienceInput } from '@common/components/form/audience';
import ModalWindow from '@common/components/modal/modal-window';
import ModalContent from '@common/components/modal/modal-content';
import './audience-modal.scss';

const AUDIENCE_MODAL = 'audience-modal';

const BaseAudienceModal = memo(({
  onSubmit,
  show,
  onShow,
  onHide,
  handleSubmit,
  children,
  title,
  submitting,
  enabledFilters
}: any) => {
  return (
    <ModalWindow
      trigger={children}
      show={show}
      onHide={onHide}
      onShow={onShow}
      size="large"
    >
      <ModalContent
        title={title}
        onHide={onHide}
        confirmButtonForm={AUDIENCE_MODAL}
        confirmButtonType="submit"
        confirmButtonLoading={!!submitting}
      >
        <form
          id={AUDIENCE_MODAL}
          className="audienceModalForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AudienceInput
            name="audience"
            enabledFilters={enabledFilters}
          />
        </form>
      </ModalContent>
    </ModalWindow>
  );
});

const form = reduxForm({ form: AUDIENCE_MODAL });
const AudienceModal = form(BaseAudienceModal);

export default AudienceModal;
