import React, { useRef, useState, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '@common/components/button';
import Icon from '@common/components/icon';
import ToggleContent from '@common/components/toggle-content';
import Confirm from '@common/components/confirm-button';
import Dropdown from '@common/components/dropdown';
import { TextInput } from '@common/components/form/inputs/text';

import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';
import { EAcademySortableTypes } from '@modules/learning/definitions';

import StatusSelector from '../status-selector';
import CourseIcon from '../module-icon';
import ModuleChildCount from '../module-child-count';

const ModuleItemComponent = ({
  index, item, section, moveModule, openModule, changeModuleStatus, canAddQuizModule,
  dragRef: sectionDragRef, removeModule, onDrop, duplicateModule, showUpgradeModal,
}) => {
  const rootRef = useRef(null); // To combine both drop and drag refs
  const { t } = useTranslation();

  const [, dropRef] = useDrop({
    accept: EAcademySortableTypes.MODULE,
    hover: (source, monitor) => {
      // Only drag within sections
      if (item.section_id !== monitor.getItem().section_id || source.id === item.id) return;

      moveModule(source.id, item.id, item.section_id);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef, previewRef] = !sectionDragRef ? useDrag({
    type: section.transparent ? EAcademySortableTypes.SECTION : EAcademySortableTypes.MODULE,
    item: () => ({
      id: item.id,
      type: section.transparent ? EAcademySortableTypes.SECTION : EAcademySortableTypes.MODULE,
      module_id: item.id,
      section_id: item.section_id,
    }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }) : [{ isDragging: false }, undefined, (c) => c];

  const content = (
    <>
      <div ref={sectionDragRef || dragRef} className="Table__Cell Table__Drag">
        <Icon type="drag_handle" />
      </div>
      <div className="Table__Cell" style={{ width: 0 }}>
        <CourseIcon module={item} />
      </div>
      <div className="Table__Cell Table__Cell__Title" style={{ lineHeight: 'initial' }}>
        <div>
          <a onClick={() => openModule(item.id)} role="link">
            {item.name || <small><Trans i18nKey="learning:section_module_item_no_title" /></small>}
          </a>
        </div>
        <small>
          <Trans
            i18nKey="learning:section_module_item_last_edited_at"
            values={{ date: moment(item.updated_at).format('D MMMM YYYY') }}
          />
        </small>
      </div>
      <div className="Table__Cell Table__Cell--size-auto">
        <ModuleChildCount module={item} />
      </div>
      <div className="Table__Cell Table__Action">
        <StatusSelector
          value={item.published}
          onChange={(value) => changeModuleStatus(item.id, value)}
        />
      </div>
      <div className="Table__Cell Table__Action">
        <Dropdown
          alignRight
          id={`module-${item.id}`}
          toggle={<Icon type="more_vert" />}
        >
          <Dropdown.Item
            onClick={() => {
              if (canAddQuizModule) {
                duplicateModule(item.id);
              } else {
                showUpgradeModal();
              }
            }}
          >
            <Trans i18nKey="learning:section_module_item_duplicate" />
          </Dropdown.Item>
          <Confirm
            title={t('learning:section_module_item_confirm_remove')}
            onConfirm={() => removeModule && removeModule(item.id, item.section_id)}
          >
            <Dropdown.Item danger>
              <Trans i18nKey="learning:section_module_item_remove" />
            </Dropdown.Item>
          </Confirm>
        </Dropdown>
      </div>
    </>
  );

  const ref = dropRef(previewRef(rootRef));

  // If the section is transparent we wrap each module in it's own transparent section element
  return section.transparent ? (
    <div ref={ref} className={section.transparent ? `Table Table--borderless${isDragging ? ' Table--dragging' : ''}` : ''}>
      <div className="Table__Row">{content}</div>
    </div>
  ) : (
    <div ref={ref} className="Table__Row" style={{ opacity: isDragging ? 0 : 1 }}>{content}</div>
  );
};

// New section drop area
const NewSectionComponent = ({ section = {}, moveSection, createNewSection, moveModuleToSection }) => {
  const [{ isOver, item }, newSectionRef] = useDrop({
    accept: [EAcademySortableTypes.SECTION, EAcademySortableTypes.MODULE],
    drop: (source) => {
      switch (source.type) {
        case EAcademySortableTypes.SECTION:
          if (source.id === section.id) return;

          return moveSection(source.id, section.id);
        case EAcademySortableTypes.MODULE: {
          const newSection = createNewSection(section.id, `module-${source.id}`);

          moveModuleToSection(source.id, newSection.id, source.section_id);
          source.section_id = newSection.id; // eslint-disable-line no-param-reassign

          break;
        }
        default:
          break;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  });

  return (
    <div
      ref={newSectionRef}
      className="Section__DragPlaceholder"
      style={{ height: isOver ? 24 : 4, margin: '6px 0', opacity: item ? 1 : 0 }}
    >
      {isOver && <Icon type="add" />}
    </div>
  );
};

const SectionComponent = ({
  item: section,
  previous,
  next,
  moveModuleToSection,
  moveSection,
  onDrop,
  createNewSection,
  removeSection,
  onNameSave,
  ...props
}) => {
  const { t } = useTranslation();
  const [sectionName, setSectionName] = useState(section.name);
  const [savingSectionName, setSavingSectionName] = useState(false);

  const {
    isAvailable: canAddQuizModule, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_QUIZ_MODULES);

  const [{ isModuleOverSection }, dropRef] = useDrop({
    accept: [EAcademySortableTypes.SECTION, EAcademySortableTypes.MODULE],
    canDrop: (source) => {
      const moduleId = source.type === EAcademySortableTypes.SECTION ? source.module_id : source.id;

      // Only allow modules or transparent sections to be dropped
      const test = !moduleId ? false : source.section_id !== section.id;

      return test;
    },
    drop: (source) => {
      const moduleId = source.type === EAcademySortableTypes.SECTION ? source.module_id : source.id;

      moveModuleToSection(
        moduleId,
        section.id,
        source.type === EAcademySortableTypes.SECTION ? source.id : source.section_id
      );
      source.section_id = section.id; // eslint-disable-line no-param-reassign
    },
    collect: (monitor) => {
      const item = monitor.getItem();

      try {
        return {
          isModuleOverSection: item && (item.type === EAcademySortableTypes.MODULE
            ? item.section_id !== section.id && monitor.isOver()
            : item.module_id && item.id !== section.id && monitor.isOver()),
        };
      } catch (err) {
        return {};
      }
    },
  });

  const [{ isDragging, item }, dragRef, previewRef] = useDrag({
    type: EAcademySortableTypes.SECTION,
    item: () => ({
      id: section.id,
      type: EAcademySortableTypes.SECTION,
      module_id: section.transparent && section.modules[0] ? section.modules[0].id : undefined,
    }),
    isDragging: (monitor) => section.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
  });

  const onCancelClick = useCallback(() => {
    setSectionName(section.name);
  }, [setSectionName, section]);

  const _onNameSave = useCallback(async () => {
    try {
      setSavingSectionName(true);
      const formattedName = sectionName.trim();
      await onNameSave(section, formattedName);
      setSectionName(formattedName);
    } finally {
      setSavingSectionName(false);
    }
  }, [onNameSave, section, sectionName, setSavingSectionName, setSectionName, t]);

  return (
    <>
      {!previous && (
        <NewSectionComponent
          moveSection={moveSection}
          moveModuleToSection={moveModuleToSection}
          createNewSection={createNewSection}
          onDrop={onDrop}
        />
      )}
      {section.transparent ? (
        <div
          className="Section Section--transparent"
          ref={previewRef}
          style={{ opacity: isDragging ? 0.5 : 1 }}
        >
          {section.modules.map((moduleItem, index) => (
            <ModuleItemComponent
              key={moduleItem.id}
              index={index}
              item={moduleItem}
              dragRef={dragRef}
              section={section}
              removeSection={removeSection}
              onDrop={onDrop}
              canAddQuizModule={canAddQuizModule}
              showUpgradeModal={showUpgradeModal}
              {...props}
            />
          ))}
        </div>
      ) : (
        <div
          ref={dropRef}
          style={{
            opacity: isModuleOverSection || isDragging ? 0.5 : 1,
          }}
        >
          <ToggleContent
            className="Section"
            previewRef={previewRef}
            handleRef={dragRef}
            title={(
              <>
                <TextInput
                  autoGrow={16}
                  value={sectionName}
                  disabled={savingSectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                />
                {
                  section.name !== sectionName && (
                    <>
                      <Button
                        isLoading={savingSectionName}
                        onClick={_onNameSave}
                        className="saveBtn"
                        size="small"
                        type="primary"
                      >
                        { t('common:save') }
                      </Button>
                      <Button
                        onClick={onCancelClick} size="small" type="default"
                      >
                        { t('common:cancel') }
                      </Button>
                    </>
                  )
                }
              </>
            )}
            action={(
              <Dropdown
                alignRight
                id={`section-${section.id}`}
                toggle={<Icon type="more_vert" />}
              >
                <Confirm
                  title={t('learning:section_confirm_remove_title')}
                  description={t('learning:section_confirm_remove_description')}
                  onConfirm={() => removeSection(section.id)}
                >
                  <Dropdown.Item danger><Trans i18nKey="learning:section_remove" /></Dropdown.Item>
                </Confirm>
              </Dropdown>
            )}
          >
            <div className="Table Table--borderless">
              {section.modules.map((moduleItem, index) => (
                <ModuleItemComponent
                  key={moduleItem.id}
                  index={index}
                  item={moduleItem}
                  section={section}
                  removeSection={removeSection}
                  onDrop={onDrop}
                  canAddQuizModule={canAddQuizModule}
                  showUpgradeModal={showUpgradeModal}
                  {...props}
                />
              ))}
            </div>
          </ToggleContent>
        </div>
      )}
      {!item || !((item.id === section.id || next) && (next && item.id === next.id)) ? (
        <NewSectionComponent
          section={section}
          moveSection={moveSection}
          moveModuleToSection={moveModuleToSection}
          createNewSection={createNewSection}
        />
      ) : (
        <div style={{ height: 20 }} />
      )}
    </>
  );
};

export default SectionComponent;
