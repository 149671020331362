import * as React from 'react';
import { Field } from 'redux-form';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

import Group from '@common/components/form/group';
import Label from '@common/components/form/label';
import PureList from '../../../../../common/components/list';
import Icon from '../../../../../common/components/icon';
import OptionsItem from './item';
import createTempId from '../../../../../common/utils/create-temp-id';

class OptionsInput extends React.Component {

  static defaultProps = {
    maxAmount: 10,
  };

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    this.handleAddOption = this.handleAddOption.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  static props;

  handleChange(item) {
    const { input: { value, onChange } } = this.props;

    const newValue = [...value].map((option) => {
      if (option.id !== item.id) return option;

      return item;
    });

    onChange(newValue);
  }

  handleAddOption(index) {
    const { input: { value, onChange }, maxAmount } = this.props;

    if (value.length >= maxAmount) return;

    onChange(R.insert(index + 1, {
      id: createTempId(),
      local: true,
      text: '',
      correct: false,
    }, value));
  }

  handleRemoveOption(index) {
    const { input: { value, onChange } } = this.props;

    onChange(R.remove(index, 1, value));

    const inputs = document.getElementsByClassName('Form__Options__Item__Input');

    if (inputs[index - 1]) inputs[index - 1].focus();
  }

  handleDrag(currentIndex, targetIndex) {
    const { input: { value, onChange } } = this.props;

    const option = value[currentIndex];

    onChange(R.insert(targetIndex, option, R.remove(currentIndex, 1, value)));
  }

  render() {
    const {
      screen, minAmount, maxAmount, input: { value }, t,
    } = this.props;

    const validAnswer = value ? value.filter((option) => option.text.length > 0) : [];
    const amountOfCorrectAnswers = validAnswer.filter((option) => option.correct).length;

    let label = t('learning:form_fields_options_answers');

    if (minAmount && validAnswer.length < minAmount) {
      label += ` (${t('learning:form_fields_options_minimum_options', { count: minAmount })})`;
    } else if (amountOfCorrectAnswers === 0) {
      label += ` (${t('learning:form_fields_options_minimum_correct_answers')})`;
    }

    return (
      <Group>
        <Label text={label} />
        <div className="Form__Options">
          <PureList
            items={value}
            renderRow={OptionsItem}
            rowProps={{
              screen,
              onChange: this.handleChange,
              onAddOption: this.handleAddOption,
              onRemoveOption: this.handleRemoveOption,
              onDrag: this.handleDrag,
            }}
          />
          {value.length < maxAmount && (
            <div
              className="Form__Options__Item Form__Options__Add"
              onClick={this.handleAddOption}
            >
              <div className="Form__Options__Item__Handler Form__Options__Item__Handler--no-cursor">
                <Icon type="add" />
              </div>
              <div className="Form__Options__Item__Text">
                {value.length === 0
                  ? t('learning:form_fields_options_add_answer')
                  : t('learning:form_fields_options_add_another_answer')}
              </div>
            </div>
          )}
        </div>
      </Group>
    );
  }
}

export default withTranslation()((props) => <Field {...props} component={OptionsInput} />);
