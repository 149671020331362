import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { DndProvider } from 'react-dnd';
import { Trans, withTranslation } from 'react-i18next';

import { selected } from '@modules/organisation/selectors/organisation';
import dndManager from '@common/utils/dnd-manager';
import { EComponentTypes } from '@modules/learning/definitions';
import updateScreen from '../../actions/update-screen';
import removeScreen from '../../actions/remove-screen';
import bulkUpdateScreens from '../../actions/bulk-update-screens';
import addQuestion from '../../actions/add-question';
import addScreen from '../../actions/add-screen';
import * as Alert from '../../../../common/services/alert';
import Bar from '../../../../common/components/bar';
import { Button } from '../../../../common/components/button';
import List from '../../../../common/components/list';
import Placeholder from '../../../../common/components/placeholder';
import QuestionItem from '../../components/question-item';
import * as screenSelector from '../../selectors/screen';

require('../../styles.scss');

const QuestionItemContainer = connect(
  () => {
    const selector = screenSelector.item();
    return (state, { item }) => ({
      // this overrides a the item provided by the List element that uses
      // LearningOnboardingItem, I did not understand why the item does not
      // update automatically, needs more investigation.
      // See https://flex-appeal.atlassian.net/browse/PD-8611
      item: selector(state, item.id),
    });
  }
)(QuestionItem);

class AcademyQuizModule extends React.Component {
  constructor(props) {
    super();

    const { module: moduleItem } = props;

    this.state = {
      order: R.pluck('id', moduleItem.screens),
      isAddingQuestion: false,
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleAddContent = this.handleAddContent.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this);
    this.handleOpenScreen = (id) => props.history.push(`${props.location.pathname}/screens/${id}`);
    this.handleDuplicateQuestion = this.handleDuplicateQuestion.bind(this);
  }

  static props;

  async handleRemoveQuestion(id) {
    const { module: moduleItem, removeScreenAction, t } = this.props;
    const { order } = this.state;

    try {
      await removeScreenAction(id, moduleItem.id);
      this.setState({
        order: order.filter((questionId) => questionId !== id)
      });
      Alert.success(t('learning:module_quiz_question_removed'));
    } catch (err) {
      if (err.status_code === 422) {
        Alert.warning(t('learning:module_active_question_required'));
      } else {
        Alert.error(t('learning:module_quiz_error_removing_question'));
      }
      throw err;
    }
  }

  async handleAddQuestion() {
    this.handleAddScreen(EComponentTypes.ELEARNING_QUESTION);
  }

  async handleAddContent() {
    this.handleAddScreen();
  }

  async handleAddScreen(type) {
    const { module: moduleItem } = this.props;

    // Get current highest index of screens
    const maxIndex = R.reduce(R.max, 0, R.pluck('index', moduleItem.screens));

    this.setState({ isAddingQuestion: true });

    await this.props.saveModuleForm();

    let response;
    if (type === EComponentTypes.ELEARNING_QUESTION) {
      response = await this.props.addQuestion(moduleItem.id, { index: maxIndex + 1 });
    } else {
      response = await this.props.addScreen(moduleItem.id, { index: maxIndex + 1 });
    }

    this.handleOpenScreen(response.screen.id);
  }

  async handleDuplicateQuestion(callback) {
    this.setState({ isAddingQuestion: true });
    await this.props.saveModuleForm();
    const duplicatedItem = await callback(); // this is what performs the backend call
    this.setState({
      isAddingQuestion: false,
      order: [...this.state.order, duplicatedItem.id]
    });
  }

  handleDrag(screenId, index) {
    const { order } = this.state;

    const newOrder = R.insert(
      index,
      screenId,
      R.remove(R.indexOf(screenId, order), 1, order),
    );

    this.setState({ order: newOrder });
  }

  async handleSaveOrder() {
    const { order } = this.state;
    const { module: moduleItem, bulkUpdateScreens: bulkUpdate, t } = this.props;

    try {
      const screens = order.map((id, index) => {
        const screen = moduleItem.screens.find((item) => item.id === id);
        return {
          id,
          index,
          components: screen.components
        };
      });

      await bulkUpdate(moduleItem.id, screens);

      Alert.success(t('learning:saved_order'));
    } catch (error) {
      Alert.forStatus(error.status_code, {
        warning: t('learning:warning_saving_order'),
        error: t('learning:error_saving_order'),
      });
    }
  }

  render() {
    const { order, isAddingQuestion } = this.state;
    const { module: moduleItem, updateScreenAction, t, organisation } = this.props;

    const items = R.sortBy(
      (screen) => R.findIndex(R.equals(screen.id), order),
      (moduleItem && moduleItem.screens) || []
    );

    return (
      <>
        <Bar>
          <h2 className="pull-left">
            <Trans
              i18nKey="learning:module_content_screens"
              values={{
                count: moduleItem && moduleItem.screens ? moduleItem.screens.length : 0
              }}
            />
          </h2>
          <div className="pull-right">
            <Button
              type="inverted-primary"
              iconRight="add"
              size="large"
              onClick={this.handleAddContent}
              isLoading={isAddingQuestion}
            >
              <Trans i18nKey="learning:module_content_add_screen" />
            </Button>
            <Button
              type="primary"
              iconRight="add"
              size="large"
              onClick={this.handleAddQuestion}
              isLoading={isAddingQuestion}
            >
              <Trans i18nKey="learning:module_quiz_add_question" />
            </Button>
          </div>
        </Bar>

        <DndProvider manager={dndManager}>
          <List
            items={items}
            renderRow={QuestionItemContainer}
            rowProps={{
              onOpen: this.handleOpenScreen,
              onChangeOrder: this.handleDrag,
              onDrop: this.handleSaveOrder,
              onUpdate: (screenId, values) => updateScreenAction(screenId, moduleItem.id, values),
              onRemove: this.handleRemoveQuestion,
              onDuplicate: this.handleDuplicateQuestion,
              moduleId: moduleItem.id,
              organisationId: organisation.id
            }}
            placeholder={(
              <Placeholder
                icon="playlist_add"
                title={t('learning:module_quiz_placeholder')}
                onCreate={this.handleAddQuestion}
              />
            )}
          />
        </DndProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organisation: selected(state)
  };
};

const mapDispatchToProps = {
  updateScreenAction: updateScreen,
  removeScreenAction: removeScreen,
  bulkUpdateScreens,
  addQuestion,
  addScreen,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withTranslation()(connector(AcademyQuizModule));
