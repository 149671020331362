import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import { AlertService } from '@common/services/alert';
import StatusSelector from '../status-selector';

type LearningPathSelectorProps = {
  onChange: (newValue: LearningPathSelectorProps['value']) => void;
  value: 'live' | 'draft';
  pathId: string;
  orgId: string;
};

const LearningPathStatusSelector = memo(({
  onChange,
  value,
  pathId,
  orgId
}: LearningPathSelectorProps) => {
  const { t } = useTranslation();

  const valueBool = value === 'live';

  const onStatusChange = useCallback(async (changedValue: boolean) => {
    try {
      const status = changedValue ? 'live' : 'draft';
      await Api.put(`/v2/organisations/${orgId}/learning-paths/${pathId}`, { status });
      onChange(status);
      AlertService.success(t('core:user_status_published'));
    } catch (error) {
      AlertService.error(t('common:something_went_wrong'));
    }
  }, [orgId, pathId, t, onChange]);

  const statusOptions = useMemo(() => {
    return [{
      label: t('common:live')
    }, {
      label: t('common:draft')
    }];
  }, [t]);

  return (
    <StatusSelector
      // @ts-expect-error
      value={valueBool}
      onChange={onStatusChange}
      customOptions={statusOptions}
    />
  );
});

export default LearningPathStatusSelector;
