import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { DndProvider } from 'react-dnd';
import { Trans, withTranslation } from 'react-i18next';
import dndManager from '@common/utils/dnd-manager';
import { selected } from '@modules/organisation/selectors/organisation';
import * as Alert from '@common/services/alert';
import Bar from '@common/components/bar';
import Permission from '@common/components/permission';
import Icon from '@common/components/icon';
import Spinner from '@common/components/spinner';
import { Button } from '@common/components/button';
import PureList from '@common/components/list';
import Placeholder from '@common/components/placeholder';
import ScreenOverviewItem from '../../components/screens-overview/item';
import { EScales } from '../../components/screen-preview';
import updateScreen from '../../actions/update-screen';
import removeScreen from '../../actions/remove-screen';
import bulkUpdateScreens from '../../actions/bulk-update-screens';
import addScreen from '../../actions/add-screen';
import '../../styles.scss';

const ETriggers = {
  BUTTON: 'button',
  PLACEHOLDER: 'placeholder',
};

class AcademyContentModule extends React.Component {
  constructor(props) {
    super();

    this.state = {
      order: R.pluck('id', props.module.screens),
      isAddingScreen: null,
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.handleAddScreen = this.handleAddScreen.bind(this);
    this.handleRemoveScreen = this.handleRemoveScreen.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleDuplicateScreen = this.handleDuplicateScreen.bind(this);
    this.handleOpenScreen = (id) => props.history.push(`${props.location.pathname}/screens/${id}`);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { module: moduleItem } = this.props;

    if (!moduleItem.screens || !nextProps.module.screens) return;

    if (moduleItem.screens.length > 0 && moduleItem.screens.length < nextProps.module.screens.length) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ order: R.append(R.last(nextProps.module.screens).id, this.state.order) });
    }
  }

  static props;

  async handleAddScreen(trigger) {
    const { module: moduleItem } = this.props;

    const maxIndex = R.reduce(R.max, 0, R.pluck('index', moduleItem.screens));

    this.setState({ isAddingScreen: trigger });

    await this.props.saveModuleForm();
    const { screen } = await this.props.addScreen(moduleItem.id, { index: maxIndex + 1 });

    this.handleOpenScreen(screen.id);
  }

  async handleDuplicateScreen(callback) {
    this.setState({ isAddingScreen: ETriggers.PLACEHOLDER });
    await this.props.saveModuleForm();
    await callback(); // this is what performs the backend call
    this.setState({ isAddingScreen: null });
  }

  async handleRemoveScreen(screenId) {
    const { module: moduleItem, removeScreenAction, t } = this.props;
    const { order } = this.state;

    try {
      await removeScreenAction(screenId, moduleItem.id);
      this.setState({
        order: order.filter((id) => id !== screenId)
      });

      Alert.success(t('learning:module_content_screen_removed'));
    } catch (err) {
      Alert.error(t('learning:module_content_error_removing_screen'));
    }
  }

  handleDrag(screenId, index) {
    const { order } = this.state;

    const newOrder = R.insert(
      index,
      screenId,
      R.remove(R.indexOf(screenId, order), 1, order),
    );

    this.setState({ order: newOrder });
  }

  async handleSaveOrder() {
    // console.log("debug handleSaveOrder this.props", this.props);
    const { order } = this.state;
    const { module: moduleItem, bulkUpdateScreens: bulkUpdate, t } = this.props;
    // console.log("debug handleSaveOrder moduleItem", moduleItem);

    try {
      const screens = order.map((id, index) => {
        const screen = moduleItem.screens.find((item) => item.id === id);
        return {
          id,
          index,
          components: screen.components
        };
      });
      await bulkUpdate(moduleItem.id, screens);
      Alert.success(t('learning:saved_order'));
    } catch (response) {
      console.log('error', response);
      Alert.forStatus(response.status_code, {
        warning: t('learning:warning_saving_order'),
        error: t('learning:error_saving_order'),
      });
    }
  }

  render() {
    const { order, isAddingScreen } = this.state;
    const { module: moduleItem, coursePermissions, t, organisation } = this.props;

    if (moduleItem) {
      moduleItem.screens = R.sortBy((screen) => R.findIndex(R.equals(screen.id), order), moduleItem.screens);
    }

    // console.log("debug order", order);
    // console.log("debug moduleItem.screens", moduleItem.screens);
    // console.log("debug AcademyContentModule this.props", this.props);

    return (
      <>
        <Bar>
          <h2 className="pull-left">
            <Trans
              i18nKey="learning:module_content_screens"
              values={{ count: moduleItem && moduleItem.screens ? moduleItem.screens.length : 0 }}
            />
          </h2>
          <Permission name={coursePermissions.update}>
            <div className="pull-right">
              <Button
                type="primary"
                iconRight="add"
                size="large"
                onClick={() => this.handleAddScreen(ETriggers.BUTTON)}
                disabled={!!isAddingScreen}
                isLoading={isAddingScreen === ETriggers.BUTTON}
              >
                <Trans i18nKey="learning:module_content_add_screen" />
              </Button>
            </div>
          </Permission>
        </Bar>

        <DndProvider manager={dndManager}>
          <PureList
            containerClassName="ScreensOverview"
            items={(moduleItem && moduleItem.screens) || []}
            renderRow={ScreenOverviewItem}
            rowProps={{
              size: 'xsmall',
              onDrag: this.handleDrag,
              onDrop: this.handleSaveOrder,
              onOpen: this.handleOpenScreen,
              onRemove: this.handleRemoveScreen,
              onUpdate: (screenId, values) => this.props.updateScreenAction(screenId, moduleItem.id, values),
              moduleId: moduleItem.id,
              organisationId: organisation.id,
              coursePermissions,
              handleDuplicateScreen: this.handleDuplicateScreen
            }}
            footer={(
              <Permission name={coursePermissions.update}>
                <div
                  key="last"
                  className={`ScreenPlaceholder${isAddingScreen ? ' ScreenPlaceholder--disabled' : ''}`}
                  onClick={!isAddingScreen ? () => this.handleAddScreen(ETriggers.PLACEHOLDER) : undefined}
                  style={{ width: 350 * EScales.xsmall - 34, height: 450 * EScales.xsmall - 2 }} // Remove padding and border widths from dimensions
                >
                  {isAddingScreen === ETriggers.PLACEHOLDER
                    ? <Spinner size="large" centered />
                    : (
                      <div>
                        <Icon type="library_add" size="large" />
                        <b><Trans i18nKey="learning:module_content_add_screen_title" /></b>
                        <span><Trans i18nKey="learning:module_content_add_screen_description" /></span>
                      </div>
                    )}
                </div>
              </Permission>
            )}
            placeholder={(
              <>
                <Permission name={coursePermissions.create}>
                  <Placeholder
                    icon="playlist_add"
                    title={t('learning:module_content_screens_placeholder_add_screen')}
                    onCreate={() => this.handleAddScreen(ETriggers.PLACEHOLDER)}
                  />
                </Permission>
                <Permission name={coursePermissions.create} fallback>
                  <Placeholder icon="campaign"><Trans i18nKey="learning:module_content_screens_placeholder" /></Placeholder>
                </Permission>
              </>
            )}
          />
        </DndProvider>
      </>
    );
  }
}

const mapDispatchToProps = {
  addScreen,
  bulkUpdateScreens,
  updateScreenAction: updateScreen,
  removeScreenAction: removeScreen,
};

const mapStateToProps = (state) => {
  return {
    organisation: selected(state)
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AcademyContentModule)
);
