import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import { DndProvider, useDrop } from 'react-dnd';
import { Trans, withTranslation } from 'react-i18next';
import * as R from 'ramda';

import { TopNavigationBar } from '@common/components/navigation-bar';
import { Api } from '@common/services/api';
import dndManager from '@common/utils/dnd-manager';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as AlertService from '../../../../common/services/alert';
import Container from '../../../../common/components/container';
import { Button } from '../../../../common/components/button';
import Permission from '../../../../common/components/permission';
import Confirm from '../../../../common/components/confirm-button';
import Placeholder from '../../../../common/components/placeholder';
import Bar from '../../../../common/components/bar';
import Spinner from '../../../../common/components/spinner';
import createTempId from '../../../../common/utils/create-temp-id';
import StatisticsContainer from './statistics';
import Section from '../../components/section';
import StatusSelector from '../../components/status-selector';
import CourseForm from '../../forms/course';
import AcademyForm from '../../forms/academy';
import OnboardingForm from '../../forms/onboarding';
import AddModuleForm from '../../forms/add-module';
import * as coursesSelector from '../../selectors/courses';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { EPermissions } from '../../../../common/definitions';
import { ECourseTypes } from '../../definitions';

function getBackUrlParam() {
  if (window.URLSearchParams) {
    const params = new URLSearchParams(window?.location?.search);
    return params.get('backUrl');
  }
  return null;
}

let container = null;
let lastValue = 0;
let pending = false;

function scroll(value) {
  lastValue += value;

  if (pending) return;

  pending = true;
  window.requestAnimationFrame(() => {
    if (!container) return;

    container.scrollTop += lastValue;

    pending = false;
    lastValue = 0;
  });
}

const ScrollArea = ({ position }) => {
  let lastUpdate = 0;

  useEffect(() => {
    [container] = document.getElementsByClassName('Content__Wrapper');
  }, []);

  const [{ isDragging }, dropRef] = useDrop({
    accept: ['section', 'module_item'],
    hover: () => {
      const now = new Date().getTime();

      if (lastUpdate > 0) {
        const diff = (now - lastUpdate) / 3;

        scroll(position === 'top' ? diff * -1 : diff);
      }

      lastUpdate = now;
    },
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
    }),
  });

  if (!isDragging) return null;

  return (
    <div
      ref={(ref) => {
        dropRef(ref);

        if (ref) {
          ref.addEventListener('dragleave', () => {
            lastUpdate = 0;
          });
        }
      }}
      className="DragnDrop__ScrollArea"
      style={{
        [position]: 0,
      }}
    />
  );
};

const updateOrder = (id, index, order) => {
  return R.insert(index, id, R.reject(R.equals(id), order));
};

const getOrder = (sections) => {
  return sections.map(R.omit(['modules']));
};

/* eslint-disable react/prefer-stateless-function */
class LearningCourseContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      order: (props.course && getOrder(props.course.sections)) || [],
      loading: props.match.params.courseId !== 'create' && !props.course,
      moduleCascadePublishId: null,
      isCreatingSection: false
    };

    this.handleOpenModule = this.handleOpenModule.bind(this);
    this.handleMoveModule = this.handleMoveModule.bind(this);
    this.handleRemoveSection = this.handleRemoveSection.bind(this);
    this.save = this.save.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.handleRemoveModule = this.handleRemoveModule.bind(this);
    this.handleDuplicateModule = this.handleDuplicateModule.bind(this);
    this.onCascadeModulePublishConfirm = this.onCascadeModulePublishConfirm.bind(this);
    this.onCascadeModulePublishClose = this.onCascadeModulePublishClose.bind(this);
    this.onSectionNameSave = this.onSectionNameSave.bind(this);
    this.handleAddNewSection = this.handleAddNewSection.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { history, match, fetchCourse, t } = this.props;

    if (match.params.courseId !== 'create') {
      try {
        this.setState({ loading: true });
        const { item: course } = await fetchCourse(match.params.courseId);

        this.setState({
          order: getOrder(course.sections),
          loading: false
        });
      } catch (err) {
        if (err.status_code === 404) {
          AlertService.warning(t('learning:course_not_found'));
        } else {
          AlertService.error(t('learning:course_error_while_fetching'));
        }

        history.push('/admin/learning');
      }
    }
  }

  static props;

  async onSectionNameSave(section, newName) {
    const newOrder = this.state.order.map((orderSection) => {
      return {
        ...orderSection,
        name: orderSection.id === section.id ? newName : orderSection.name
      };
    });
    try {
      await this.save(newOrder);
      AlertService.success(this.props.t('academy:section_name_save_success'));
    } catch (error) {
      AlertService.error(this.props.t('academy:section_name_save_error'));
      throw error;
    }
  }

  handleOpenModule(id) {
    this.props.history.push(`/admin/learning/courses/${this.props.match.params.courseId}/modules/${id}`);
  }

  // Move module within a section
  handleMoveModule(sourceId, targetId, sectionId) {
    this.setState({
      order: this.state.order.map((section) => {
        if (section.id !== sectionId) return section;

        // Update order of modules inside section
        return R.assoc('module_ids', updateOrder(sourceId, R.indexOf(targetId, section.module_ids), section.module_ids), section);
      }),
    });
  }

  // Move module from one section to another
  handleMoveModuleToSection = (sourceId, targetId, currentSectionId) => {
    this.setState({
      order: this.state.order.map((section) => {
        if (section.id !== targetId && section.id !== currentSectionId) return section;

        // Remove module from old section
        if (section.id === currentSectionId) {
          return R.assoc('module_ids', R.reject(R.equals(sourceId), section.module_ids), section);
        }

        return R.assoc('module_ids', R.append(sourceId, section.module_ids), section);
      }).filter((section) => (!section.transparent && !section.local) || section.module_ids.length > 0),
    });

    this.saveOrder();
  };

  // Change order of sections
  handleMoveSection = (sourceId, targetId) => {
    const currentValue = R.find(R.propEq('id', sourceId), this.state.order);
    const cleanOrder = R.reject(R.propEq('id', sourceId), this.state.order);
    const newIndex = sourceId ? R.findIndex(R.propEq('id', targetId), cleanOrder) + 1 : 0;

    const order = R.insert(newIndex, currentValue, cleanOrder);

    this.setState({
      order
    });

    this.saveOrder();
  };

  // Create a new section for a module to be placed in
  handleCreateNewSection = (afterSection, id) => {
    const newIndex = afterSection ? R.findIndex(R.propEq('id', afterSection), this.state.order) + 1 : 0;

    const newSection = {
      id: id || createTempId(),
      local: true,
      transparent: true,
      module_ids: [],
    };

    this.setState({
      order: R.insert(newIndex, newSection, this.state.order),
    });

    return newSection;
  };

  async handleAddNewSection() {
    const { t } = this.props;

    const section = {
      id: createTempId(),
      name: t('learning:course_new_section_name'),
      local: true,
      transparent: false,
      module_ids: [],
    };
    const newOrder = R.append(section, this.state.order);

    try {
      this.setState({ isCreatingSection: true });
      await this.save(newOrder);
      AlertService.success(this.props.t('academy:section_creation_success'));
    } catch (error) {
      AlertService.error(this.props.t('common:something_went_wrong'));
      throw error;
    } finally {
      this.setState({ isCreatingSection: false });
    }
  }

  handleStatusChange = async (publish) => {
    const { course, t } = this.props;

    if (!course) return;

    if (publish) {
      this.props.history.push(`/admin/learning/courses/${course.id}/publish/${course.type}`);
    } else {
      await this.props.updateCourse(course.id, { published: false });

      AlertService.success(t('learning:course_unpublished'));
    }
  };

  async save(order = null) {
    const { course, updateCourse } = this.props;

    const newOrder = (order || this.state.order).map((section, index) => ({
      id: section.local || section.transparent ? undefined : section.id,
      index,
      name: section.name || null,
      transparent: section.transparent || false,
      modules: section.module_ids,
    }));

    const { item } = await updateCourse(course.id, {
      sections: newOrder,
    });

    this.setState({ order: getOrder(item.sections) });
  }

  async saveOrder() {
    try {
      await this.save();
      AlertService.success(this.props.t('learning:course_changes_saved'));
    } catch (error) {
      AlertService.error(this.props.t('learning:course_cant_save_changes'));
      throw error;
    }
  }

  handleRemove = async () => {
    const { history, course, removeCourse, t } = this.props;

    if (!course) return;

    await removeCourse(course.id);

    AlertService.success(t('learning:course_has_been_removed'));

    history.push(course.type === ECourseTypes.ONBOARDING ? '/admin/learning/onboarding' : '/admin/learning/academy');
  };

  handleChangeModuleStatus = async (id, published) => {
    const { course, t, organisation, updateModule } = this.props;

    if (!course) return;

    if (published) {
      const orgId = organisation.id;
      const res = await Api.get(`/v2/organisations/${orgId}/modules/${id}`);
      const unpublishedScreen = res.data.screens.find((scr) => !scr.published);
      if (unpublishedScreen) {
        return this.setState({ moduleCascadePublishId: id });
      }
    }

    await updateModule(id, { published }, course.id);
    AlertService.success(published
      ? t('learning:course_has_been_published')
      : t('learning:course_has_been_unpublished'));
  };

  async onCascadeModulePublishConfirm(publishScreens) {
    const { course, t, updateModule } = this.props;
    // copying handleChangeModuleStatus approach with truthiness check
    if (!course) return;
    const { moduleCascadePublishId } = this.state;
    try {
      await updateModule(
        moduleCascadePublishId,
        { published: true },
        course.id,
        publishScreens
      );
      AlertService.success(t('learning:course_has_been_published'));
    } catch (err) {
      if (err.status_code === 422) {
        AlertService.warning(t('learning:module_active_question_required'));
      } else {
        AlertService.error(t('learning:module_update_error'));
      }
      throw err;
    }
    this.onCascadeModulePublishClose();
  }

  onCascadeModulePublishClose() {
    this.setState({ moduleCascadePublishId: null });
  }

  async handleRemoveSection(id) {
    const section = R.find(R.propEq('id', id), this.state.order);

    if (!section) return;

    const newSections = !section.transparent ? section.module_ids.map((moduleId) => ({
      id: createTempId(),
      name: null,
      local: true,
      transparent: true,
      module_ids: [moduleId],
    })) : [];

    const currentIndex = R.findIndex(R.propEq('id', id), this.state.order);

    const newOrder = R.pipe(
      R.reject(R.propEq('id', id)),
      R.insertAll(currentIndex, newSections),
    )(this.state.order);

    try {
      await this.save(newOrder);
      AlertService.success(this.props.t('academy:section_deletion_success'));
    } catch (err) {
      AlertService.error(this.props.t('common:something_went_wrong '));
      throw err;
    }
  }

  async handleRemoveModule(id, sectionId) {
    const { removeModule, t } = this.props;

    try {
      await removeModule(id, sectionId);

      this.setState({
        order: this.state.order.map((section) => {
          if (section.id !== sectionId) return section;

          return {
            ...section,
            module_ids: R.reject(R.equals(id), section.module_ids),
          };
        }).filter((section) => section.module_ids.length > 0),
      });

      AlertService.success(t('learning:module_removed'));
    } catch (err) {
      AlertService.warning(t('learning:warning_remove_module'));
      throw err;
    }
  }

  async handleDuplicateModule(id) {
    const { course, duplicateModule, t } = this.props;

    try {
      // TODO: no section data available so can't add to state
      const { section } = await duplicateModule(id, course.id);

      if (section.transparent) { // in the UI the module is not within a section collapsible
        this.setState({
          order: R.append(R.omit(['modules'], {
            ...section,
            module_ids: R.pluck('id', section.modules),
          }), this.state.order),
        });
      } else {
        const newOrder = this.state.order.map((orderSection) => {
          if (orderSection.id === section.id) {
            return {
              ...orderSection,
              module_ids: section.modules.map((mod) => mod.id),
            };
          }
          return orderSection;
        });
        this.setState({ order: newOrder });
      }

      AlertService.success(t('learning:module_duplicated'));
    } catch (err) {
      AlertService.warning(t('learning:warning_duplicate_module'));
      throw err;
    }
  }

  renderContent() {
    const { order, loading } = this.state;
    if (loading) {
      return <Spinner centered size="large" />;
    }

    const { match, course, history, t } = this.props;

    const placeholder = (
      <Placeholder
        image="/static/images/modules-placeholder.svg"
        title={t('learning:course_placeholder_title')}
        description={t('learning:course_placeholder_description')}
        action={(
          <AddModuleForm course={course} onOpenCourse={this.handleOpenModule}>
            <Button type="primary" iconRight="add">
              <Trans i18nKey="learning:course_new_module" />
            </Button>
          </AddModuleForm>
        )}
      />
    );

    if (match.params.courseId === 'create') {
      return (
        <>
          <CourseForm
            form="create-course"
            history={history}
            type={match.params.type}
            show
          />
          { placeholder }
        </>
      );
    }

    const lookUp = {
      sections: R.indexBy(R.prop('id'), R.map(R.omit(['modules']), course ? course.sections : [])),
      modules: R.indexBy(R.prop('id'), R.flatten(R.pluck('modules', course ? course.sections : []))),
    };
    const items = order.map((values) => {
      const { module_ids: ids } = values;
      const section = lookUp.sections[values.id] || values;

      return R.assoc(
        'modules',
        ids.map((moduleId) => {
          return R.assoc('section_id', values.id, lookUp.modules[moduleId]);
        }),
        section
      );
    });

    return (
      <Switch>
        <Route
          path="/admin/learning/courses/:courseId/statistics"
          component={StatisticsContainer}
        />
        <Route>
          {
            items.length <= 0 ?
              placeholder :
              (
                <>
                  <Bar>
                    <h2 className="pull-left">
                      <Trans
                        i18nKey="learning:course_modules_count"
                        values={{ count: Object.keys(lookUp.modules).length }}
                      />
                    </h2>
                    <div className="pull-right">
                      <AddModuleForm
                        course={course}
                        onOpenCourse={this.handleOpenModule}
                      >
                        <Button type="primary" iconRight="add" size="large">
                          <Trans i18nKey="learning:course_new_module" />
                        </Button>
                      </AddModuleForm>
                    </div>
                    <Button
                      iconRight="add"
                      onClick={this.handleAddNewSection}
                      type="primary"
                      size="large"
                      disabled={this.state.isCreatingSection}
                    >
                      { t('learning:course_new_section') }
                    </Button>
                  </Bar>
                  <DndProvider manager={dndManager}>
                    <ScrollArea position="top" />
                    {items.map((section, i) => (
                      <Section
                        key={section.id}
                        item={section}
                        previous={items[i - 1]}
                        next={items[i + 1]}
                        openModule={this.handleOpenModule}
                        moveModule={this.handleMoveModule}
                        onDrop={this.saveOrder}
                        moveModuleToSection={this.handleMoveModuleToSection}
                        moveSection={this.handleMoveSection}
                        changeModuleStatus={this.handleChangeModuleStatus}
                        createNewSection={this.handleCreateNewSection}
                        removeSection={this.handleRemoveSection}
                        removeModule={this.handleRemoveModule}
                        duplicateModule={this.handleDuplicateModule}
                        onNameSave={this.onSectionNameSave}
                      />
                    ))}

                    <ScrollArea position="bottom" />
                  </DndProvider>
                </>
              )
          }
        </Route>
      </Switch>
    );
  }

  render() {
    const {
      history, match, course, networks, functions, organisation, t,
    } = this.props;
    const { moduleCascadePublishId } = this.state;

    return (
      <Container name="Category" className="LearningNewSection">
        <TopNavigationBar
          className="LearningNewSection__TopNavigation"
          breadcrumbs={[
            { name: t('learning:breadcrumb_learning_environment') },
            course && course.type === ECourseTypes.ONBOARDING && {
              name: t('learning:breadcrumb_onboarding'), path: '/admin/learning/onboarding',
            },
            course && course.type === ECourseTypes.ACADEMY && {
              name: t('learning:breadcrumb_academy'), path: '/admin/learning/academy',
            },
            course && {
              name: match.params.courseId === 'create' ? t('learning:course_new_course_title') : course && course.name,
            },
          ]}
          form={CourseForm}
          formProps={{
            course,
            history,
          }}
          image={(course && course.header_image_url) || null}
          title={match.params.courseId === 'create' ? t('learning:course_new_course_title') : course && course.name}
          description={course && course.description}
          action={(
            <div className="Align" style={{ flexShrink: 0 }}>
              {course && course.type === ECourseTypes.ONBOARDING && (
                <>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
                    <OnboardingForm
                      form={`onboarding-course-detail/${course.id}`}
                      {...{ course, networks, functions, organisation }}
                    />
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE}>
                    <Confirm
                      title={t('learning:course_confirm_remove_title')}
                      description={t('learning:course_confirm_remove_description')}
                      onConfirm={this.handleRemove}
                    >
                      <Button size="large" icon="delete" />
                    </Confirm>
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
                    <StatusSelector value={course ? course.published : false} onChange={this.handleStatusChange} />
                  </Permission>
                </>
              )}
              {course && course.type === ECourseTypes.ACADEMY && (
                <>
                  <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE}>
                    <AcademyForm
                      form={`academy-course-detail/${course.id}`}
                      {...{ course, networks, functions, organisationId: organisation.id }}
                    />
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_REMOVE}>
                    <Confirm
                      title={t('learning:course_confirm_remove_title')}
                      description={t('learning:course_confirm_remove_description')}
                      onConfirm={this.handleRemove}
                    >
                      <Button size="large" icon="delete" />
                    </Confirm>
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE}>
                    <StatusSelector value={course ? course.published : false} onChange={this.handleStatusChange} />
                  </Permission>
                </>
              )}
              <Button
                size="large"
                onClick={() => {
                  const backUrl = getBackUrlParam();
                  history.push(backUrl || `/admin/learning/${course ? course.type : ''}`);
                }}
              >
                <Trans i18nKey="learning:course_back_button" />
              </Button>
            </div>
          )}
          tabs={course && course.published && [
            { name: t('learning:course_tabs_content'), to: `/admin/learning/courses/${match.params.courseId}`, exact: true },
            { name: t('learning:course_tabs_statistics'), to: `/admin/learning/courses/${match.params.courseId}/statistics` },
          ]}
        />

        <Container.Content>
          {this.renderContent()}
          {
            moduleCascadePublishId && (
              <Confirm
                title={t('learning:form_module_confirm_publish_title')}
                description={t('learning:form_module_confirm_publish_description')}
                check={t('learning:form_module_confirm_publish_check')}
                onConfirm={this.onCascadeModulePublishConfirm}
                onCancel={this.onCascadeModulePublishClose}
              />
            )
          }
        </Container.Content>
      </Container>
    );
  }

}

const mapStateToProps = (state, props) => ({
  course: coursesSelector.item(state, props.match.params.courseId),
  organisation: organisationSelector.selected(state),
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
  organisationId: state.organisation.selected.id,
});

const mapDispatchToProps = {
  fetchCourse: require('../../actions/fetch-course').default,
  updateCourse: require('../../actions/update-course').default,
  removeCourse: require('../../actions/remove-course').default,
  updateModule: require('../../actions/update-module').default,
  removeModule: require('../../actions/remove-module').default,
  duplicateModule: require('../../actions').duplicateModule
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(
  pageWrapper(EEventNames.VISITED_COURSE_PAGE)(LearningCourseContainer)
));
