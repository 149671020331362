import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ModuleStatus = ({ published, small }) => {
  const { t } = useTranslation();

  const classNames = ['Status', `Status--state-${published ? 'published' : 'concept'}`];
  if (small) classNames.push('Status--small');

  const label = published
    ? t('learning:module_status_active')
    : t('learning:module_status_concept');

  return (
    <div className={classNames.join(' ')}>{!small && label}</div>
  );
};

export default ModuleStatus;
