import * as R from 'ramda';
import Api from '../../../common/services/api';
import { LEARNING_FETCH_COURSE } from './index';

export default (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data, meta } = await Api.get(`/v2/organisations/${selected.id}/courses/${id}?include=users`);

  const modules = [];
  data.sections = data.sections.map((section) => {
    section.modules.map((item) => modules.push(R.assoc('screen_count', item.screens.length, item)));

    return {
      ...R.omit(['modules'], section),
      module_ids: R.pluck('id', section.modules),
    };
  }, []);

  return dispatch({
    type: LEARNING_FETCH_COURSE,
    item: data,
    modules: R.map(R.omit(['screens']), modules),
    related: meta.related,
  });
};
