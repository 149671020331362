import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../common/components/icon';
import Tooltip from '../../../../../common/components/tooltip';

const OptionsInputItem = ({
  item, screen, index, onRemoveOption, onAddOption, onChange, onDrag,
}) => {
  const { t } = useTranslation();
  const rootRef = React.useRef(null);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current && item.text === '') inputRef.current.focus();
  }, [inputRef]);

  const [, dropRef] = useDrop({
    accept: ['QUESTION_OPTION'],
    hover: (source) => {
      if (source.index === index) return;

      onDrag(source.index, index);
      source.index = index; // eslint-disable-line no-param-reassign
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'QUESTION_OPTION',
    item: () => ({
      index,
      id: item.id,
    }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleKeyPress = (e) => {
    // $FlowFixMe
    if (e.charCode === 13) {
      e.preventDefault();

      onAddOption(index);
    }
  };

  const handleKeyDown = (e) => {
    // $FlowFixMe
    if (e.keyCode === 8 && item.text === '') {
      e.preventDefault();

      onRemoveOption(index);
    }
  };

  const handleChange = (e) => onChange(R.omit(['row_type'], { ...item, text: e.target.value }));
  const handleToggleCorrect = () => onChange({ ...item, correct: !item.correct });

  const isExisting = screen && screen.published && !(item.id && item.id.startsWith('temp'));

  const toggleCorrectButton = (
    <div
      onClick={!isExisting ? handleToggleCorrect : undefined}
      className={[
        'Form__Options__Item__Status',
        isExisting && 'Form__Options__Item__Status--disabled',
        item.correct && 'Form__Options__Item__Status--correct',
      ].filter((c) => !!c).join(' ')}
    >
      {item.correct && <Icon type="check" />}
      {item.correct
        ? t('learning:form_fields_options_item_marked_correct')
        : t('learning:fields_options_item_mark_correct')}
    </div>
  );

  return (
    <div
      ref={dropRef(previewRef(rootRef))}
      className="Form__Options__Item"
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div className="Form__Options__Item__Handler" ref={dragRef}>
        <Icon type="drag_handle" />
      </div>
      <input
        ref={inputRef}
        className="Form__Options__Item__Input"
        data-lpignore="true"
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={item.text}
        placeholder={t('learning:form_fields_options_item_text_placeholder')}
        maxLength={250}
      />
      <small className="Form__Options__Item__MaxLength">
        {item.text.length}
        /250
      </small>
      {isExisting ? (
        <Tooltip title={t('learning:form_fields_options_item_disabled_tooltip')}>
          {toggleCorrectButton}
        </Tooltip>
      ) : toggleCorrectButton}
    </div>
  );
};

export default OptionsInputItem;
