import React, {
  PropsWithChildren,
  memo, useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import Bar from '@common/components/bar';
import Spinner from '@common/components/spinner';
import { TopNavigationBar } from '@common/components/navigation-bar';
import { Api } from '@common/services/api';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import List from '@common/components/list';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Placeholder from '@common/components/placeholder';
import CourseListItem from './course-list-item';
import LearningFilterItem from '../filter-item';
import { ApiLearningPathResponse } from '@modules/learning/types/learning-paths';
import LearningPathStatusSelector from './learning-path-status-selector';
import { useSimplePredicatesList } from '@common/utils/predicates';
import { APIAcademyCourse } from '@modules/learning/types/objects';
import AddCoursesModal from './add-courses-modal';
import { AlertService } from '@common/services/alert';
import LearningPathEditorModal from './learning-path-editor-modal';
import dndManager from '@common/utils/dnd-manager';
import LearningPathAudienceModal from './learning-path-audience-modal';
import { StoreState } from '@common/types/store';

import './learning-path-detail.scss';

const LearningPathDetail = memo(({ match }: any) => {
  const { params: { pathId }, url } = match;
  const { t } = useTranslation();

  const [learningPath, setLearningPath] = useState<ApiLearningPathResponse | null>(null);
  const orgId = useAppSelector(selected).id;
  const translations = useAppSelector((state: StoreState) => state.language.translations);

  const coursesRef = useRef<APIAcademyCourse[] | null>(null);

  useEffect(() => {
    const apiUrl = `/v2/organisations/${orgId}/learning-paths/${pathId}`;
    Api.get<ApiLearningPathResponse>(apiUrl).then((response) => {
      setLearningPath(response);
      coursesRef.current = response?.data?.courses || [];
    });
  }, [orgId, pathId, setLearningPath, coursesRef]);

  const courses = learningPath?.data?.courses;

  const breadcrumbs = useMemo(() => {
    const folders = url.split('/');
    folders.pop();
    const crumbs = [
      { name: t('learning:breadcrumb_learning_environment') },
      {
        name: t('learning:learning_paths'),
        path: folders.join('/')
      }
    ];
    const name = learningPath?.data?.name;
    if (name) {
      crumbs.push({ name });
    }
    return crumbs;
  }, [learningPath, url]);

  const onCourseOrderChange = useCallback((
    sourceCourseId: string,
    targetCourseId: string
  ) => {
    setLearningPath((prevPath) => {
      if (!prevPath) return prevPath;

      const sourceCourse = prevPath.data.courses.find((c) => c.id === sourceCourseId);
      if (!sourceCourse) return prevPath;

      const targetIndex = prevPath.data.courses.findIndex((c) => c.id === targetCourseId);
      const newCourses = prevPath.data.courses.filter((c) => c.id !== sourceCourseId);
      newCourses.splice(targetIndex, 0, sourceCourse);

      return {
        ...prevPath,
        data: {
          ...prevPath.data,
          courses: newCourses
        }
      };
    });
  }, [setLearningPath]);

  const setCourses = useCallback((newCourses: APIAcademyCourse[]) => {
    setLearningPath((prevPath) => {
      if (!prevPath) return prevPath;
      return {
        ...prevPath,
        data: { ...prevPath.data, courses: newCourses }
      };
    });
  }, [setLearningPath]);

  const resetOrder = useCallback(() => {
    if (!coursesRef.current) return;
    setCourses(coursesRef.current);
  }, [setCourses, coursesRef]);

  const onCourseDrop = useCallback(async () => {
    try {
      if (!courses) return;
      const newOrder = courses.map((c) => c.id);
      const prevOrder = coursesRef.current!.map((c) => c.id);
      if (JSON.stringify(newOrder) === JSON.stringify(prevOrder)) {
        return;
      }
      const coursesUrl = `/v2/organisations/${orgId}/learning-paths/${pathId}/courses`;
      const payload = { courses: courses.map((c) => c.id) };
      await Api.patch(coursesUrl, payload);
      coursesRef.current = courses;
      AlertService.success(t('learning:saved_order'));
    } catch (error) {
      AlertService.error(t('learning:error_saving_order'));
      resetOrder();
      throw error;
    }
  }, [coursesRef, courses, pathId, orgId, t, resetOrder]);

  const backUrl = useMemo(() => {
    const folders = url.split('/');
    folders.pop();
    return folders.join('/');
  }, [url]);

  const updateCourses = useCallback((newCourses: APIAcademyCourse[]) => {
    coursesRef.current = newCourses;
    setCourses(newCourses);
  }, [setCourses, coursesRef]);

  const history = useHistory();
  const closeEditor = useCallback((updatedPath) => {
    if (updatedPath) {
      setLearningPath((prevPath) => {
        if (!prevPath) return prevPath;
        return {
          ...prevPath,
          data: {
            ...prevPath.data,
            name: updatedPath.data.name,
            description: updatedPath.data.description,
            audience: updatedPath.data.audience
          },
          meta: updatedPath.meta || prevPath.meta
        };
      });
    }
    history.push(url);
  }, [history, url, setLearningPath]);
  const openEditor = useCallback(() => history.push(`${url}/edit`), [history, url]);

  const onStatusChange = useCallback((status: 'live' | 'draft') => {
    setLearningPath((prevPath) => {
      return {
        ...prevPath,
        data: {
          ...prevPath!.data,
          status
        }
      };
    });
  }, [setLearningPath]);

  const form = useMemo(() => {
    return ({ children }: PropsWithChildren<{}>) => {
      return (
        <span className="TopNavigationBar__Row__Info" onClick={openEditor}>
          { children }
        </span>
      );
    };
  }, [openEditor]);

  const onModalHide = useCallback(() => history.push(url), [url, history]);

  const simplePredicatesList = useSimplePredicatesList(
    {
      ...learningPath?.meta?.related,
      translations,
    },
    learningPath?.data?.audience
  );

  return (
    <Container name="Category" className="LearningNewSection LearningPathDetail">
      <TopNavigationBar
        className="LearningNewSection__TopNavigation"
        breadcrumbs={breadcrumbs}
        form={form}
        title={learningPath?.data?.name || <Spinner />}
        description={learningPath?.data?.description}
        action={(
          <div className="Align" style={{ flexShrink: 0 }}>
            {
              learningPath && (
                <>
                  <Link to={`${url}/audience`}>
                    <LearningFilterItem
                      icon="visibility__filled"
                      placeholder={t('learning:form_academy_visibility')}
                    >
                      {
                        simplePredicatesList ||
                        t('common:everyone')
                      }
                    </LearningFilterItem>
                  </Link>
                  <LearningPathStatusSelector
                    value={learningPath.data?.status || 'draft'}
                    orgId={orgId}
                    pathId={pathId}
                    onChange={onStatusChange}
                  />
                </>
              )
            }
            <Link to={backUrl}>
              <Button size="large">
                { t('learning:course_back_button') }
              </Button>
            </Link>
          </div>
        )}
      />

      <Container.Content>
        {
          !learningPath ?
            <Spinner centered size="large" /> :
            (
              <>
                <Route path={`${url}/courses`}>
                  <AddCoursesModal
                    updateCourses={updateCourses}
                    path={learningPath}
                    orgId={orgId}
                    onHide={onModalHide}
                  />
                </Route>
                <Route path={`${url}/edit`}>
                  <LearningPathEditorModal
                    onHide={closeEditor}
                    orgId={orgId}
                    learningPath={learningPath.data}
                  />
                </Route>
                <Route path={`${url}/audience`}>
                  <LearningPathAudienceModal
                    orgId={orgId}
                    learningPath={learningPath}
                    onHide={closeEditor}
                  />
                </Route>

                <Bar>
                  { /* eslint-disable-next-line jsx-a11y/heading-has-content */ }
                  <h2 className="pull-left" />
                  <div className="pull-right">
                    <Link to={`${url}/courses`}>
                      <Button type="primary" iconRight="add" size="large">
                        { t('learning:add_courses') }
                      </Button>
                    </Link>
                  </div>
                </Bar>
                <DndProvider manager={dndManager}>
                  <List
                    className="OnboardingCourses"
                    items={courses || []}
                    renderRow={CourseListItem}
                    rowProps={{
                      onChangeOrder: onCourseOrderChange,
                      onDrop: onCourseDrop,
                      resetOrder,
                      courses,
                      setCourses,
                      orgId,
                      pathId
                    }}
                    placeholder={(
                      <Placeholder
                        image="/static/images/modules-placeholder.svg"
                        title={t('learning:no_courses')}
                        action={(
                          <Link to={`${url}/courses`}>
                            <Button type="primary" iconRight="add">
                              { t('learning:add_courses') }
                            </Button>
                          </Link>
                        )}
                      />
                    )}
                  />
                </DndProvider>
              </>
            )
        }
      </Container.Content>
    </Container>
  );
});

export default LearningPathDetail;
