import React, { memo, MouseEventHandler } from 'react';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import { useTranslation } from 'react-i18next';
import { Icon } from '@common/components/icon';

type DuplicateDeleteDropdownProps = {
  deleteModalTitle?: string;
  onDelete: (confirm: boolean) => void;
  onDuplicate: MouseEventHandler;
};

const DuplicateDeleteDropdown = memo(({
  deleteModalTitle,
  onDelete,
  onDuplicate
}: DuplicateDeleteDropdownProps) => {

  const { t } = useTranslation();

  return (
    <Dropdown
      alignRight
      className="DuplicateDeleteDropdown"
      toggle={<Icon type="more_vert" />}
    >
      <Dropdown.Item onClick={onDuplicate}>
        { t('learning:academy_item_duplicate') }
      </Dropdown.Item>
      <ConfirmButton
        danger
        title={
          deleteModalTitle ||
          t('learning:screens_overview_item_confirm_remove')
        }
        onConfirm={onDelete}
        confirmText={t('organisation:user_actions_delete_confirm_text')}
      >
        <Dropdown.Item danger>
          { t('common:delete') }
        </Dropdown.Item>
      </ConfirmButton>
    </Dropdown>
  );
});

export default DuplicateDeleteDropdown;
