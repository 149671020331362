import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';

import Icon from '@common/components/icon';
import ComponentsList from '@modules/learning/components/components-list';
import AddComponentForm from '../component/add';

import { ACADEMY_REMOVE_COMPONENT, ACADEMY_UPDATE_COMPONENTS_ORDER } from '@modules/learning/actions';
import swap from '@common/utils/swap';
import { ContentComponent } from '@modules/learning/types/objects';
import { EPlanPackageConfig } from '@common/definitions';
import PlanPackageOverlay from '@common/components/plan-package-overlay/plan-package-overlay';

type FeebackProps = {
  type: 'correct' | 'incorrect';
  questionId: string;
  disabled: boolean;
  components: ContentComponent[];
  showUpgradeModal: () => void;
};

const Feedback = ({
  type, questionId, disabled, components, showUpgradeModal,
}: FeebackProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDelete = useCallback((componentId) => {
    dispatch({
      type: ACADEMY_REMOVE_COMPONENT,
      questionId,
      componentId,
      feedbackType: `${type}_feedback`,
    });
  }, [dispatch, questionId, type]);

  const handleDrag = useCallback((componentId, index) => {
    const order = sortBy(components, ['index']).map(({ id }) => id);
    dispatch({
      type: ACADEMY_UPDATE_COMPONENTS_ORDER,
      questionId,
      feedbackType: `${type}_feedback`,
      items: swap(order, index, order.indexOf(componentId)),
    });
  }, [dispatch, components, questionId, type]);

  return (
    <PlanPackageOverlay
      className="FormQuestionFeedback__Content"
      blur="medium"
      disabled={disabled && !components.length}
      showUpgradeModal={showUpgradeModal}
    >
      <div className="FormQuestionFeedback__Title">
        <span>
          <Icon
            size="large"
            type={type === 'correct' ? 'check_circle__filled' : 'close_round__filled'}
            color={type === 'correct' ? 'green' : 'red'}
          />
          {type === 'correct' ?
            t('learning:form_question_feedback_correct') :
            t('learning:form_question_feedback_incorrect')}
        </span>
      </div>
      <ComponentsList
        questionId={questionId}
        items={components}
        disabled={disabled}
        onDelete={onDelete}
        onDrag={handleDrag}
      />
      <AddComponentForm
        type={`${type}_feedback`}
        packageId={EPlanPackageConfig.ACADEMY_HINTS_FEEDBACK}
        id={questionId}
        components={components}
      />
    </PlanPackageOverlay>
  );
};

export default Feedback;
