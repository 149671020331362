import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import * as R from 'ramda';

import { AlertService } from '@services/alert';
import * as searchUtil from '@common/utils/search';
import Container from '@common/components/container';
import Permission from '@common/components/permission';
import { Button } from '@common/components/button';
import Rows from '@common/components/rows';
import Placeholder from '@common/components/placeholder';
import Bar from '@common/components/bar';
import Spinner from '@common/components/spinner';
import { EPredicateFilters, optionsPreviewer } from '@common/components/predicates-filter';
import { PredicateSelectorMultiSelect } from '@common/components/predicates-filter/filter-multi-select';
import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';
import CourseItem from '../../components/academy-item';

import type { StoreState } from '../../../../common/types/store';
import * as coursesSelector from '../../selectors/courses';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { changeCourseTypeAction, duplicateCourse as duplicateCourseAction } from '../../actions';
import fetchCoursesAction from '../../actions/fetch-courses';
import updateCourseAction from '../../actions/update-course';
import removeCourseAction from '../../actions/remove-course';
import updateCoursesOrderAction from '../../actions/update-courses-order';

import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { combineClassNames } from '@common/utils/combineClassNames';
import { Api } from '@common/services/api';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import dndManager from '@common/utils/dnd-manager';
import { EPermissions, EPlanPackageConfig, EPredicateFields, EPredicateOperators } from '@common/definitions';
import { Course } from '@modules/learning/types/objects';
import { Network, Organisation } from '@common/types/objects';
import { ApiResponse } from '@common/services/api/types';
import { useHistory } from 'react-router';

const mapStateToProps = (state: StoreState) => ({
  courses: coursesSelector.academy(state),
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
  organisation: organisationSelector.selected(state),
  organisationId: state.organisation.selected.id,
});

const mapDispatchToProps = {
  changeCourseType: changeCourseTypeAction,
  fetchCourses: fetchCoursesAction,
  updateCourse: updateCourseAction,
  removeCourse: removeCourseAction,
  updateCoursesOrder: updateCoursesOrderAction,
  duplicateCourse: duplicateCourseAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  organisationId?: string;
  courses: Course[];
  networks: Network[];
  organisation: Organisation;
  functions: any;
  fetchCourses: any;
  duplicateCourse: any;
  removeCourse: any;
  changeCourseType: any;
  updateCoursesOrder: any;
  updateCourse: any;
};

type CoursesCountMeta = {
  counts: {
    total: number;
  }
};

const getOrder = (items: Course[]) => {
  return items
    .filter(({ type }) => type === 'academy')
    .map(({ id }) => id);
};

const LearningAcademyContainer = ({
  courses, networks, functions, organisationId, organisation,
  fetchCourses, duplicateCourse, removeCourse, changeCourseType, updateCoursesOrder, updateCourse,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(true);
  const [predicates, setPredicates] = useState([]);
  const [search, setSearch] = useState('');
  const [amountOfOnboardingCourses, setAmountOfOnboardingCourses] = useState<number | undefined>(undefined);
  const [order, setOrder] = useState<string[]>(() => courses.map(({ id }) => id));

  useEffect(() => {
    const base = `/v3/organisations/${organisationId}`;
    const init = async () => {
      const [data, meta, newNetworks, newFunctions] = await Promise.all([
        fetchCourses(),
        Api.get<ApiResponse<{}, CoursesCountMeta>>(`/v2/organisations/${organisationId}/courses?limit=0&type=onboarding`),
        Api.get(`${base}/networks?include_all=true`),
        Api.get(`${base}/functions`),
      ]);
      setAmountOfOnboardingCourses(meta.meta?.counts.total);
      setOrder(getOrder(data.items));
      // @ts-expect-error
      setPredicates(formatPredicates(t, newNetworks, newFunctions));
      setIsFetching(false);
    };
    init();
  }, [
    organisationId, fetchCourses, setOrder, t,
    setAmountOfOnboardingCourses, setPredicates, setIsFetching,
  ]);

  const filteredItems = getFilteredItems(courses, predicates, search || '', order);

  const {
    isAvailable: canCreateCourses, showUpgradeModal: showUpgradeToCreateCoursesModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY, filteredItems?.length);
  const {
    isAvailable: canMoveCoursesToOnboarding, showUpgradeModal: showUpgradeToMoveModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_ONBOARDING, amountOfOnboardingCourses);

  const onDuplicate = useCallback(async (id) => {
    if (canCreateCourses) {
      try {
        await duplicateCourse(id);
        const data = await fetchCourses();
        setOrder(getOrder(data.items));
        AlertService.success(t('learning:duplicated_course'));
      } catch (err) {
        AlertService.warning(t('learning:warning_duplicate_course'));
      }
    } else {
      showUpgradeToCreateCoursesModal();
    }
  }, [
    canCreateCourses, getOrder, setOrder, showUpgradeToCreateCoursesModal,
    duplicateCourse, fetchCourses, t,
  ]);

  const onDelete = useCallback(async (id) => {
    await removeCourse(id);
    AlertService.success(t('learning:removed_course'));
  }, [removeCourse, t]);

  const onMoveCourseToOnboarding = useCallback(async (courseId: string) => {
    if (canMoveCoursesToOnboarding) {
      try {
        await changeCourseType(courseId);
        setAmountOfOnboardingCourses((amountOfOnboardingCourses && amountOfOnboardingCourses + 1) || 1);
        setOrder((previousOrder) => previousOrder.filter((id) => id !== courseId));
      } catch (e) {
        AlertService.error(t('learning:unable_to_move_course'));
      }
    } else {
      showUpgradeToMoveModal();
    }
  }, [
    amountOfOnboardingCourses, canMoveCoursesToOnboarding, changeCourseType,
    showUpgradeToMoveModal, setAmountOfOnboardingCourses, setOrder, t,
  ]);

  const onChangeOrder = useCallback((sourceId: string, targetId: string) => {
    const index = R.indexOf(targetId, order);
    setOrder(R.insert(index, sourceId, R.reject(R.equals(sourceId), order)));
  }, [order, setOrder]);

  const onDrop = useCallback(async () => {
    try {
      await updateCoursesOrder(order);
      AlertService.success(t('learning:saved_order'));
    } catch (response: any) {
      AlertService.forStatus(response.status_code, {
        warning: t('learning:warning_saving_order'),
        error: t('learning:error_saving_order'),
      });
    }
  }, [updateCoursesOrder, order, t]);

  const onPublish = useCallback((id: string, publish: boolean) => {
    if (publish) {
        history.push(`/admin/learning/courses/${id}/publish/academy`);
      } else {
        updateCourse(id, { published: false });

        AlertService.success(t('learning:unpublished_course'));
      }
  }, [history, updateCourse, t]);

  const onCreateCourse = useCallback(() => {
    if (canCreateCourses) {
      history.push('/admin/learning/academy/create');
    } else {
      showUpgradeToCreateCoursesModal();
    }
  }, [canCreateCourses, history, showUpgradeToCreateCoursesModal]);

  const getItemProps = () => ({
    onOpen: (id: string) => history.push(`/admin/learning/courses/${id}`),
    onDelete,
    onChangeOrder,
    onPublish,
    onDrop,
    onDuplicate,
    onChangeCourseType: onMoveCourseToOnboarding,
    networks,
    functions,
    organisationId,
  });

  return (
    <Container.Content>
      <Bar>
        <h2 className="pull-left">
          <Trans i18nKey="learning:academy_courses_count" values={{ count: filteredItems.length }} />
        </h2>
        <div className="pull-right">
          <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_CREATE}>
            <Button
              size="large"
              type="primary"
              iconRight="add"
              onClick={onCreateCourse}
            >
              <Trans i18nKey="learning:academy_add_course" />
            </Button>
          </Permission>
        </div>
      </Bar>
      {isFetching ? (
        <Spinner centered size="large" />
      ) : (
        <>
          <Bar>
            <FiltersToolbar
              predicates={predicates}
              setPredicates={setPredicates}
              organisation={organisation}
              debounce={false}
              onSearch={setSearch}
            />
          </Bar>
          <DndProvider manager={dndManager}>
            <Rows
              items={filteredItems}
              itemsPerRow={window.innerWidth < 1337 ? 3 : 4}
              render={CourseItem}
              rowProps={getItemProps}
              rowClassName={combineClassNames('Academy__Collections', {
                'Academy__Collections--responsive': window.innerWidth < 1337,
              })}
              placeholder={search ? (
                <Placeholder
                  title={t('learning:academy_course_search_placeholder_title')}
                  action={t('learning:academy_course_search_placeholder_action')}
                />
              ) : (
                <Placeholder
                  image="/static/images/courses-placeholder.svg"
                  title={t('learning:academy_course_placeholder_title')}
                  description={t('learning:academy_course_placeholder_description')}
                  action={(
                    <Button
                      iconRight="add"
                      type="primary"
                      onClick={onCreateCourse}
                    >
                      <Trans i18nKey="learning:academy_new_course" />
                    </Button>
                  )}
                />
              )}
            />
          </DndProvider>
        </>
      )}
    </Container.Content>
  );
};

export default connector(
  pageWrapper(EEventNames.VISITED_ACADEMY_PAGE)(LearningAcademyContainer)
);

export function formatPredicates(t: any, networks: any, functions: any) {
  const predicates = [{
    filter: EPredicateFilters[EPredicateFields.NETWORK],
    value: {
      attribute: EPredicateFields.NETWORK,
      comparison: EPredicateOperators.IN,
      value: []
    },
    options: networks?.data || [],
  }, {
    filter: EPredicateFilters[EPredicateFields.FUNCTION],
    value: {
      attribute: EPredicateFields.FUNCTION,
      comparison: EPredicateOperators.IN,
      value: []
    },
    options: functions?.data || []
  }, {
    key: 'status',
    filter: {
      icon: 'assignment',
      context: 'status',
      selector: PredicateSelectorMultiSelect,
      previewer: optionsPreviewer,
    },
    value: {
      comparison: EPredicateOperators.IN,
      value: [],
    },
    options: [{
      id: 'concept',
      name: t('learning:module_status_concept')
    }, {
      id: 'active',
      name: t('learning:module_status_active')
    }]
  }];
  return predicates;
}

export function getFilteredItems(
  courses: any[], predicates: any[], search: string, items: any[]
) {
  const coursesLookup = R.indexBy(R.prop('id'), courses);

  const networkFilter = predicates.find((p: any) => {
    return p.value.attribute === EPredicateFields.NETWORK;
  })?.value.value;
  const functionFilter = predicates.find((p: any) => {
    return p.value.attribute === EPredicateFields.FUNCTION;
  })?.value.value;
  const statusFilter = predicates.find((p: any) => {
    return p.key === 'status';
  })?.value.value;

  const filteredItems = items.map((id) => coursesLookup[id]).filter((item: any) => {
    return (
      !!item &&
      (
        search ?
          searchUtil.includesInQuery(item, search, ['name', 'description']) :
          true
      ) && (
        networkFilter?.length ?
          !!_.intersection(
            networkFilter,
            item.filters.networks.map((n: any) => n.id)
          ).length :
          true
      ) && (
        functionFilter?.length ?
          !!_.intersection(
            functionFilter,
            item.filters.functions.map((n: any) => n.id)
          ).length :
          true
      ) && (
        statusFilter?.length ?
          !!_.intersection(
            statusFilter,
            [item.published ? 'active' : 'concept']
          ).length :
          true
      )
    );
  });
  return filteredItems;
}
