import * as React from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';

const LectureChildCount = ({ module: moduleItem }) => {
  switch (moduleItem.type) {
    case 'quiz':
      return (
        <div className="ModuleChildCount">
          <Icon type="help" />
          <Trans i18nKey="learning:module_child_count_quiz" values={{ count: moduleItem.screen_count }} />
        </div>
      );
    case 'content':
      return (
        <div className="ModuleChildCount">
          <Icon type="filter_none" />
          <Trans i18nKey="learning:module_child_count_content" values={{ count: moduleItem.screen_count }} />
        </div>
      );
    default:
      return null;
  }
};

export default LectureChildCount;
