import Api from '../../../common/services/api';
import { LEARNING_REMOVE_COURSE } from './index';

export default (courseId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v2/organisations/${selected.id}/courses/${courseId}`);

  return dispatch({
    type: LEARNING_REMOVE_COURSE,
    courseId,
  });
};
