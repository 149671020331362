import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SubNavigationBar } from '@common/components/navigation-bar';
import { selected } from '@modules/organisation/selectors/organisation';
import Api from '@common/services/api';
import { useAppSelector } from '@common/hooks';
import Route, { PermissionRoute } from '../../common/components/route';
import Permission from '../../common/components/permission';
import RouteNotFound from '../../common/components/route-not-found';
import OnboardingContainer from './containers/onboarding';
import AcademyContainer from './containers/academy';
import CourseContainer from './containers/course';
import PublishOnboardingContainer from './containers/publish/onboarding';
import PublishAcademyContainer from './containers/publish/academy';
import ModuleContainer from './containers/module';
import ScreenContainer from './containers/screen';
import * as userSelector from '../core/selectors/logged-user';
import * as organisationSelector from '../organisation/selectors/organisation';
import { EComponentTypes, EPermissions } from '../../common/definitions';
import LearningPathDetail from './components/learning-paths/learning-path-detail';

require('./styles.scss');

const LearningSideNavigation = () => {
  const { t } = useTranslation();
  return (
    <SubNavigationBar title={t('core:tab_elearning')}>
      <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW}>
        <SubNavigationBar.Item
          icon="work"
          path="/admin/learning/onboarding"
          title={t('learning:template_sub_nav_onboarding')}
        />
      </Permission>
      <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW}>
        <SubNavigationBar.Item
          icon="school"
          path="/admin/learning/academy"
          title={t('learning:template_sub_nav_academy')}
        />
      </Permission>
    </SubNavigationBar>
  );
};

const LearningTemplate = ({ organisation }) => {
  const rootUrl = organisation.enabled_components.includes(EComponentTypes.ONBOARDING) ?
    '/admin/learning/onboarding' :
    '/admin/learning/academy';

  return (
    <>
      {
        organisation.enabled_components.includes(EComponentTypes.ACADEMY) &&
        organisation.enabled_components.includes(EComponentTypes.ONBOARDING) && (
          <Route
            exact
            path={[
              '/admin/learning/onboarding',
              '/admin/learning/onboarding/statistics',
              '/admin/learning/academy',
              '/admin/learning/academy/paths/list'
            ]}
            component={LearningSideNavigation}
          />
        )
      }
      <Switch>
        <Route
          path="/admin/learning/courses/:courseId/publish/onboarding"
          component={PublishOnboardingContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/publish/academy"
          component={PublishAcademyContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/modules/:moduleId/screens/:screenId"
          component={ScreenContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/modules/:moduleId"
          component={ModuleContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId"
          component={CourseContainer}
        />
        <PermissionRoute
          exact
          path={['/admin/learning/onboarding', '/admin/learning/onboarding/statistics']}
          component={OnboardingContainer}
          permission={{ name: EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW }}
        />
        { /* the LearningPathDetail route needs to precede the AcademyContainer one
        like it's specified here */}
        <PermissionRoute
          path="/admin/learning/academy/paths/list/:pathId"
          orgId={organisation.id}
          component={LearningPathDetail}
          permission={{ name: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW }}
        />
        <Route
          exact
          path="/admin/learning/:type/:courseId"
          component={CourseContainer}
        />
        <PermissionRoute
          path="/admin/learning/academy"
          component={AcademyContainer}
          permission={{ name: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW }}
        />
        <Redirect exact from="/admin/learning" to={rootUrl} />
        <Route component={RouteNotFound} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  permissions: userSelector.permissions(state),
});

export default connect(mapStateToProps)(LearningTemplate);
