import React, { memo, useMemo } from 'react';
import Container from '@common/components/container';
import { TopNavigationBar }
  from '@common/components/navigation-bar/top-navigation-bar/top-navigation-bar';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import Courses from './courses';
import LearningPaths
  from '@modules/learning/components/learning-paths/learning-paths';

const Academy = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const learningPathsUrl = `${url}/paths/list`;
  const tabs = useMemo(() => {
    return [
      {
        name: t('learning:courses'),
        to: url,
        exact: true,
      },
      {
        name: t('learning:learning_paths'),
        to: learningPathsUrl,
        exact: true
      }
    ];
  }, [t, learningPathsUrl]);

  return (
    <Container name="Academy">
      <TopNavigationBar
        breadcrumbs={[
          { name: t('learning:breadcrumb_learning_environment') },
          { name: t('learning:breadcrumb_academy') }
        ]}
        title={t('learning:breadcrumb_academy')}
        learnMoreHref={t('learning:academy_learn_more')}
        tabs={tabs}
      />
      <Switch>
        <Route path={learningPathsUrl} exact>
          <LearningPaths />
        </Route>
        <Route>
          <Courses history={history} />
        </Route>
      </Switch>
    </Container>
  );
});

export default Academy;
