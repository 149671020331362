import * as R from 'ramda';
import Api from '../../../common/services/api';

export const ACADEMY_BULK_UPDATE_SCREENS = 'academy/BULK_UPDATE_SCREENS';

const whitelist = ['id', 'index', 'published'];

export default (moduleId, screens) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = screens.map(R.pick(whitelist));

  await Api.put(`/v2/organisations/${selected.id}/modules/${moduleId}/screens`, payload);

  return dispatch({
    type: ACADEMY_BULK_UPDATE_SCREENS,
    screens,
  });
};
