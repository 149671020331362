import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import ModalWindow from '@common/components/modal/modal-window';
import ModalContent from '@common/components/modal/modal-content';
import { Row, Group } from '@common/components/form';
import { TextInputHookForm as TextInput } from '@common/components/form/inputs/text';
import { AlertService } from '@common/services/alert';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { ApiLearningPath } from '@modules/learning/types/learning-paths';

type LearningPathEditorModalProps = {
  orgId: string;
  onHide: (pathResponse?: ApiResponse<ApiLearningPath>) => void;
  learningPath?: ApiLearningPath;
};

type FormValues = {
  name?: string;
  description?: string;
};

const LearningPathEditorModal = memo(({
  onHide,
  orgId,
  learningPath
}: LearningPathEditorModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: learningPath?.name || '',
      description: learningPath?.description || '',
    }
  });
  const onConfirm = handleSubmit(async (data: FormValues) => {
    try {
      setLoading(true);
      const pathsUrl = `/v2/organisations/${orgId}/learning-paths`;
      const formattedValues = {
        name: data.name!.trim(),
        description: data.description!.trim(),
      };
      let result: ApiResponse<ApiLearningPath>;
      if (learningPath) {
        result = await Api.put<ApiResponse<ApiLearningPath>>(
          `${pathsUrl}/${learningPath.id}`,
          formattedValues
        );
      } else {
        result = await Api.post<ApiResponse<ApiLearningPath>>(pathsUrl, {
          ...formattedValues,
          courses: [],
          status: 'draft'
        });
      }
      onHide(result);
    } catch (error: any) {
      const msg = error.type === 'validation_error' ?
        t('common:validation_error') : t('common:something_went_wrong');
      AlertService.error(msg);

      setLoading(false);
      throw error;
    }
  });

  const argumentlessOnHide = useCallback(() => onHide(), [onHide]);

  return (
    <ModalWindow
      show
      size="small"
      onHide={argumentlessOnHide}
    >
      <ModalContent
        title={t('learning:new_learning_path')}
        onHide={argumentlessOnHide}
        confirmButtonText={t('common:save')}
        onConfirm={onConfirm}
        confirmButtonLoading={loading}
        confirmButtonDisabled={!formState.isValid}
      >
        <form>
          <Row className="nameRow">
            <Group>
              <TextInput
                name="name"
                label={t('learning:learning_path_title')}
                control={control}
                placeholder={t('learning:learning_path_title')}
                maxLength={30}
                rules={{ required: 'learning:learning_path_title_required' }}
              />
            </Group>
          </Row>
          <Row>
            <Group>
              <TextInput
                name="description"
                label={t('learning:description')}
                control={control}
                placeholder={t('learning:description')}
                maxLength={160}
              />
            </Group>
          </Row>
        </form>
      </ModalContent>
    </ModalWindow>
  );
});

export default LearningPathEditorModal;
