import { Api } from '@common/services/api';
import React, { memo, useEffect, useState, PropsWithChildren } from 'react';
import { Button } from '@common/components/button';
import Confirm from '@common/components/confirm-button';
import { useTranslation } from 'react-i18next';

type ActivateCourseButtonProps = {
  orgId: string;
  courseId: string;
  isLoading: boolean;
  onPopupConfirm: (...args: any[]) => any;
};

const ActivateCourseButton = memo(({
  orgId,
  courseId,
  isLoading,
  children,
  onPopupConfirm
}: PropsWithChildren<ActivateCourseButtonProps>) => {

  const [showConfirm, setShowConfirm] = useState<null | boolean>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!orgId || !courseId) return;
    const url = `/v2/organisations/${orgId}/courses/${courseId}`;
    Api.get(url).then((response: any) => {
      setShowConfirm(hasUnpublishedEntities(response?.data));
    });
  }, [orgId, courseId, setShowConfirm]);

  // deactivate the <form> submit when we want to submit programmatically
  // from the <Confirm> popup down here
  const buttonType = showConfirm ? 'button' : 'submit';

  const button = (
    <Button
      disabled={typeof showConfirm !== 'boolean'}
      type="primary"
      buttonType={buttonType}
      size="large"
      isLoading={isLoading}
    >
      { children }
    </Button>
  );

  if (showConfirm) {
    return (
      <Confirm
        title={t('learning:would_you_like_to_publish_concept_screens_and_modules_too')}
        check={t('learning:publish_concept_screens_and_modules_too')}
        onConfirm={onPopupConfirm}
      >
        { button }
      </Confirm>
    );
  }
  return button;
});

function hasUnpublishedEntities(course: any) {
  for (let s = 0; s < course.sections.length; s++) {
    const section = course.sections[s];
    for (let m = 0; m < section.modules.length; m++) {
      const module = section.modules[m];
      if (!module.published) {
        return true;
      }
      for (let i = 0; i < module.screens.length; i++) {
        const screen = module.screens[i];
        if (!screen.published) {
          return true;
        }
      }
    }
  }
  return false;
}

export default ActivateCourseButton;
