import React, { PropsWithChildren, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import Modal from '@common/components/modal';
import { Button } from '@common/components/button';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';
import { EModuleTypes } from '../../definitions';
import addModuleAction from '../../actions/add-module';
import { Course } from '@modules/learning/types/objects';

type Props = PropsWithChildren<{
  addModule: any;
  course: Course;
  onOpenCourse: (id: string) => void;
}>;

const AddModuleForm = ({
  children, course, addModule, onOpenCourse,
}: Props) => {
  const [isCreatingContent, setIsCreatingContent] = useState(false);
  const [isCreatingQuiz, setIsCreatingQuiz] = useState(false);

  const {
    isAvailable: canAddQuizModule, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_QUIZ_MODULES);

  const onCreateNewContentModule = useCallback(async () => {
    setIsCreatingContent(true);
    const { item } = await addModule(course.id, { type: EModuleTypes.CONTENT });
    onOpenCourse(item.id);
  }, []);

  const onCreateNewQuizModule = useCallback(async () => {
    setIsCreatingQuiz(true);
    const { item } = await addModule(course.id, { type: EModuleTypes.QUIZ });
    onOpenCourse(item.id);
  }, []);
  return (
    <Modal
      list
      title={(
        <Trans
          i18nKey="learning:form_add_module_modal_title"
          values={{ courseName: course && course.name }}
          components={[<b>a</b>]}
        />
      )}
      size="small"
      className="AddModuleForm"
      content={(
        <div className="AddModuleForm__Options">
          <div className="AddModuleForm__Options__Item">
            <h5><Trans i18nKey="learning:form_add_module_content_module_title" /></h5>
            <img alt="content" src="/static/images/onboarding/content.svg" />
            <small><Trans i18nKey="learning:form_add_module_content_module_description" /></small>
            <Button
              type="blue"
              icon="sort"
              onClick={onCreateNewContentModule}
              isLoading={isCreatingContent}
              disabled={isCreatingContent}
            >
              <Trans i18nKey="learning:form_add_module_choose_content_module" />
            </Button>
          </div>
          <div className="AddModuleForm__Options__Item">
            <h5><Trans i18nKey="learning:form_add_module_quiz_module_title" /></h5>
            <img alt="content" src="/static/images/onboarding/quiz.svg" />
            <small><Trans i18nKey="learning:form_add_module_quiz_module_description" /></small>
            <Button
              type="purple"
              icon="extension"
              onClick={canAddQuizModule ? onCreateNewQuizModule : showUpgradeModal}
              isLoading={isCreatingQuiz}
              disabled={isCreatingQuiz}
            >
              <Trans i18nKey="learning:form_add_module_choose_quiz_module" />
            </Button>
          </div>
        </div>
      )}
    >
      {children}
    </Modal>
  );
};

const mapDispatchToProps = {
  addModule: addModuleAction,
};

export default connect(undefined, mapDispatchToProps)(AddModuleForm);
