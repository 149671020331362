import React, { useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';

import Icon from '@common/components/icon';
import { EComponentTypes } from '@modules/learning/definitions';
import { getComponentListDescription } from '@modules/learning/utils/get-component-list';
import StatusSelector from '../status-selector';
import ScreenDropdown from '../screens-overview/screen-dropdown';

const LearningOnboardingItem = ({
  item,
  index,
  onChangeOrder,
  onOpen,
  onUpdate,
  onRemove,
  onDrop,
  onDuplicate,
  organisationId,
  moduleId
}) => {
  const { t } = useTranslation();

  const [, dropRef] = useDrop({
    accept: ['question'],
    hover: (source) => {
      if (source.id === item.id) return;
      onChangeOrder(source.id, index);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'question',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleChangeStatus = (published) => onUpdate(item.id, { published });

  const itemId = item.id;
  const onItemRemove = useCallback(() => {
    onRemove(itemId);
  }, [itemId, onRemove]);

  const points = getPoints(item.question);

  return (
    <div
      className="Section Section--transparent"
      ref={dropRef}
      style={{ paddingLeft: 0, opacity: isDragging ? 0 : 1, marginBottom: 12 }}
    >
      <div className="Table Table--borderless" ref={previewRef}>
        <div className="Table__Row">
          <div ref={dragRef} className="Table__Cell Table__Drag">
            <Icon type="drag_handle" />
          </div>
          <div className="Table__Cell">
            {getIcon(item)}
          </div>
          <div className="Table__Cell Table__Cell__Title Table__Cell__Title--grow">
            <a role="button" onClick={() => onOpen(item.id)}>
              {getTitle(item, t)}
            </a>
          </div>
          <div className="Table__Cell Table__Cell--size-auto">
            {
              typeof points === 'number' && (
                <div className="ModuleChildCount">
                  <Icon type="star" />
                  <div>
                    <Trans
                      i18nKey="learning:question_item_question_points"
                      values={{ count: points }}
                    />
                  </div>
                </div>
              )
            }
          </div>
          <div className="Table__Cell Table__Action">
            <StatusSelector value={item.published} onChange={handleChangeStatus} />
          </div>
          <div className="Table__Cell Table__Action">
            <ScreenDropdown
              organisationId={organisationId}
              moduleId={moduleId}
              screen={item}
              handleDuplicateScreen={onDuplicate}
              onRemove={onItemRemove}
              deleteModalTitle={t('learning:form_screen_confirm_remove_question')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function getIcon(item) {
  const { question = { parameters: {} } } = item;
  const isQuestion = question.type === EComponentTypes.ELEARNING_QUESTION;
  return (
    <div className={`AcademyModuleIcon AcademyModuleIcon--color-${isQuestion ? 'purple' : 'blue'}`}>
      <Icon type={isQuestion ? 'help' : 'sort'} />
    </div>
  );
}

function getTitle(item, t) {
  const { question = { parameters: {} } } = item;
  if (question.type === EComponentTypes.ELEARNING_QUESTION) {
    return question?.parameters?.text || question?.text || (
      <small>
        <Trans i18nKey="learning:question_item_no_question" />
      </small>
    );
  }

  return (
    <small>
      {getComponentListDescription(item.components, t)}
    </small>
  );
}

function getPoints(question) {
  if (typeof question?.parameters?.points === 'number') {
    return question.parameters.points;
  }
  if (typeof question?.points === 'number') {
    return question.points;
  }
}

export default LearningOnboardingItem;
