import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';

import Icon from '@common/components/icon';
import { Button } from '@common/components/button';
import Dropdown from '@common/components/dropdown';
import Permission from '@common/components/permission';
import Confirm from '@common/components/confirm-button';
import StatusSelector from '../status-selector';
import AcademyForm from '../../forms/academy';

import { EPermissions } from '@common/definitions';
import type { Course } from '../../types/objects';

type Props = {
  index: number;
  item: Course,
  onOpen: (id: string) => void,
  onDelete: (id: string) => void,
  onPublish: (id: string, publish: boolean) => void,
  onChangeOrder: (sourceId: string, targetId: string) => void,
  onDrop: () => Promise<void>,
  onDuplicate: (id: string) => Promise<void>,
  onChangeCourseType: (id: string) => Promise<void>,
};

type Source = {
  id: string;
};

const LearningAcademyItem = ({
  index,
  item,
  onOpen,
  onPublish,
  onDelete,
  onChangeOrder,
  onDrop,
  onDuplicate,
  onChangeCourseType,
}: Props) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);

  const [, dropRef] = useDrop({
    accept: ['course'],
    hover: (source: Source) => {
      if (source.id === item.id) return;

      onChangeOrder(source.id, item.id);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: 'course',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleChangeStatus = (publish: boolean) => setTimeout(() => onPublish(item.id, publish), 0);

  const classNames = ['AcademyItem'];
  if (isOpen) classNames.push('AcademyItem--open');

  return (
    <div
      className={classNames.join(' ')}
      // @ts-expect-error
      ref={dragRef(dropRef(ref))}
      style={{ opacity: isDragging ? 0 : 1, marginBottom: 12 }}
    >
      <div className="AcademyItem__Header">
        <div
          className="AcademyItem__Header__Image"
          style={{
            backgroundImage: (item.header_image_url && `url(${item.header_image_url})`) || undefined,
          }}
        />
        <div className="AcademyItem__Header__Actions">
          <span className="AcademyItem__Header__Actions__Info"><Trans i18nKey="learning:academy_drag_to_reoder" /></span>
          <Permission name={[EPermissions.ORGANISATION_ACADEMY_COURSES_CREATE, EPermissions.ORGANISATION_ACADEMY_COURSES_REMOVE]}>
            <Dropdown
              alignRight
              onToggle={(open: boolean) => setOpen(open)}
              id={`academy-item-${item.id}`}
              toggle={<Icon type="more_vert" />}
            >
              <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_CREATE}>
                <Dropdown.Item onClick={() => onDuplicate(item.id)}>
                  <Trans i18nKey="learning:academy_item_duplicate" />
                </Dropdown.Item>
              </Permission>
              <Dropdown.Item onClick={() => void onChangeCourseType(item.id)}>
                {t('learning:move_to_onboarding')}
              </Dropdown.Item>
              <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_REMOVE}>
                <Confirm
                  title={t('learning:academy_item_confirm_remove_course_title')}
                  description={t('learning:academy_item_confirm_remove_course_description')}
                  onConfirm={() => onDelete(item.id)}
                >
                  <Dropdown.Item danger><Trans i18nKey="learning:academy_item_remove" /></Dropdown.Item>
                </Confirm>
              </Permission>
            </Dropdown>
          </Permission>
        </div>
        <div className="AcademyItem__Header__Overlay">
          <Button type="inverted" onClick={() => onOpen(item.id)}><Trans i18nKey="learning:academy_item_open" /></Button>
        </div>
      </div>
      <div className="AcademyItem__Content">
        <h4 className="AcademyItem__Content__Name">{item.name}</h4>
        {item.description ? <div className="AcademyItem__Content__Description">{item.description}</div> : undefined}
        <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE}>
          <div className="AcademyItem__Content__Form">
            <AcademyForm
              form={`academy-course/${item.id}`}
              course={item}
            />
            <StatusSelector
              // @ts-expect-error
              value={item.published}
              onChange={handleChangeStatus}
            />
          </div>
        </Permission>
      </div>
    </div>
  );
};

export default LearningAcademyItem;
