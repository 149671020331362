import * as React from 'react';
import * as R from 'ramda';

import { Select } from '@common/components/form/inputs/select';
import { combineClassNames } from '../../../../utils/combineClassNames';
import { Message } from '../../message';

const padNumer = (number: number) => (number < 10 ? `0${number}` : `${number}`);

export type TimePickerInputOwnProps = {
  value?: string;
  disabled?: boolean;
  minutesPerStep?: number;
  minValue?: string;
  maxValue?: string;
  placeholder?: string,
  warning?: string | boolean,
  error?: string | boolean,
  onChange?: (newValue?: string) => void;
  onBlur?: (e: any) => void;
};

export const TimePickerInput = ({
  value,
  disabled,
  minValue,
  maxValue,
  placeholder,
  warning,
  error,
  minutesPerStep = 15,
  onChange,
  onBlur,
}: TimePickerInputOwnProps) => {
  const stepsPerHour = 60 / minutesPerStep;

  const [minimumHours, minimumMinutes] = minValue ? minValue.split(':') : ['0', '0'];
  const [maxHours, maxMinutes] = maxValue ? maxValue.split(':') : ['24', '0'];
  const offset = parseInt(minimumHours, 10) * stepsPerHour + Math.ceil(parseInt(minimumMinutes, 10) / minutesPerStep);

  const hours = R.range(offset, (parseInt(maxHours) * stepsPerHour + parseInt(maxMinutes) * stepsPerHour));

  const options = hours.map((i) => {
    const hour = Math.floor(i / stepsPerHour);
    const minute = (i - hour * stepsPerHour) * minutesPerStep;
    const optionValue = `${padNumer(hour)}:${padNumer(minute)}`;
    return {
      value: optionValue,
      label: optionValue,
    };
  });

  return (
    <>
      <Select
        className={combineClassNames('TimePicker', {
          'TimePicker--warning': !!warning,
          'TimePicker--invalid': !warning && !!error,
          'TimePicker--disabled': !!disabled,
        })}
        value={value}
        options={options}
        onChange={(option) => {
          if (onChange) onChange(option?.value);
        }}
        onBlur={(...args) => {
          if (onChange) onChange(value);
          if (onBlur) onBlur(...args);
        }}
        disabled={disabled}
        placeholder={placeholder}
        clearable={false}
      />
      <Message error={error} warning={warning} />
    </>
  );
};
