import React, { createRef, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Group from '@common/components/form/group';
import Label from '@common/components/form/label';
import Row from '@common/components/form/row';
import TextInput from '@common/components/form/inputs/text';
import TextareaInput from '@common/components/form/inputs/textarea';
import Feedback from '../feedback';
import Options from '../fields/options';

import * as draftSelector from '../../selectors/draft';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { combineClassNames } from '@common/utils/combineClassNames';
import { Question, QuestionScreen } from '@modules/learning/types/objects';
import { StoreState } from '@common/types/store';
import { EPlanPackageConfig } from '@common/definitions';
import PlanPackageOverlay from '@common/components/plan-package-overlay/plan-package-overlay';

type FormData = Question;

type Props = {
  question?: Question;
  screen: QuestionScreen;
};

type OwnProps = InjectedFormProps<FormData> & ConnectedProps<typeof reduxConnector> & Props;

const QuestionForm = ({
  question, screen, initialize,
}: OwnProps) => {
  const { t } = useTranslation();
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (question) {
      initialize(question);
      if (!question.parameters.text) inputRef.current?.focus();
    }
  }, []);

  const {
    isAvailable: canAddHintsAndFeedback, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_HINTS_FEEDBACK);

  return (
    <>
      <form className="Form">
        <Row>
          <Group>
            <Label
              minLength={10}
              maxLength={250}
              forInput="text" text={t('learning:form_question_question_label')}
            />
            <TextInput
              ref={inputRef}
              name="parameters.text"
              maxLength={250}
              placeholder={t('learning:form_question_question_placeholder')}
            />
          </Group>
        </Row>
        <Row>
          <Options name="parameters.options" minAmount={2} screen={screen} />
        </Row>
        <Row>
          <Group>
            <Label forInput="hint" text={t('learning:form_question_hint_label')} />
            <PlanPackageOverlay
              opacity="none"
              disabled={!canAddHintsAndFeedback && !question.parameters.hint}
              showUpgradeModal={showUpgradeModal}
            >
              <TextareaInput
                name="parameters.hint"
                disabled={!canAddHintsAndFeedback && !question.parameters.hint}
                className={combineClassNames({
                  'QuestionHint__Textarea--disabled': !canAddHintsAndFeedback && !question.parameters.hint,
                })}
                rows={6}
                placeholder={t('learning:form_question_hint_placeholder')}
              />
            </PlanPackageOverlay>
          </Group>
        </Row>
      </form>
      <div style={{ height: 40 }} />
      <Row>
        <Group>
          <Label
            text={t('learning:form_question_feedback_label')}
            description={t('learning:form_question_feedback_tooltip')}
          />
          <Feedback
            type="correct"
            disabled={!canAddHintsAndFeedback}
            questionId={screen.question.id}
            components={question.parameters.correct_feedback}
            showUpgradeModal={showUpgradeModal}
          />
          <Feedback
            type="incorrect"
            disabled={!canAddHintsAndFeedback}
            questionId={screen.question.id}
            components={question.parameters.incorrect_feedback}
            showUpgradeModal={showUpgradeModal}
          />
        </Group>
      </Row>
    </>
  );
};

const reduxConnector = connect((state: StoreState, props: Props) => ({
  question: draftSelector.question(state, props.screen.question) as Question,
}));

export default reduxForm<FormData, Props>({
  form: 'question',
  initialValues: {
    parameters: {
      text: '',
      hint: '',
      options: [],
      points: 1,
      correct_feedback: [],
      incorrect_feedback: [],
    }
  },
  validate: require('../validators/question').default,
})(reduxConnector(QuestionForm));
