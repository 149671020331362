import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Row, Group, DatePickerInput } from '@common/components/form';
import TimePickerInput from '@common/components/form/inputs/time';
import NumberInput from '@common/components/form/inputs/number';
import { RadioButton } from '@common/components/radio-button';
import { Message } from '@common/components/form/message';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';

type OwnProps = {
  deadlineDynamic: boolean;
  setDeadlineDynamic: (value: boolean) => void;
};

const CourseDeadline = ({ deadlineDynamic, setDeadlineDynamic }: OwnProps) => {
  const { t } = useTranslation();
  const [warning, setWarning] = useState('');

  const {
    isAvailable: canAddDynamicDeadlines, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_DYNAMIC_DEADLINES);

  return (
    <div className="AcademyDeadline">
      <Row className="AcademyDeadline__TypeSelect">
        <Group>
          <RadioButton
            label={t('learning:form_academy_deadline_date')}
            value={false}
            currentValue={deadlineDynamic}
            onChange={() => setDeadlineDynamic(false)}
          />
        </Group>
      </Row>
      {!deadlineDynamic &&
      <Row>
        <Group style={{ flex: '0 0 auto', width: 135 }}>
          <DatePickerInput
            name="deadline.date"
            minDate={moment()}
          />
        </Group>
        <Group style={{ flex: '0 0 auto', width: 128 }}>
          <TimePickerInput
            name="deadline.time"
          />
        </Group>
      </Row>}
      <Row className="AcademyDeadline__TypeSelect">
        <Group>
          <RadioButton
            label={t('learning:form_academy_deadline_days')}
            description={t('learning:form_academy_deadline_days_tooltip')}
            value
            currentValue={deadlineDynamic}
            onChange={() => {
              if (canAddDynamicDeadlines) {
                setDeadlineDynamic(true);
              } else {
                showUpgradeModal();
              }
            }}
          />
        </Group>
      </Row>
      {deadlineDynamic &&
      <Row className="AcademyDeadline__Dynamic">
        <Group style={{ flex: '0 0 auto', width: 135 }}>
          <NumberInput
            name="deadline_after_days_invited"
            min={0}
            max={10000}
            disabled={!canAddDynamicDeadlines}
            canBeEmpty={false}
            onWarning={(value) => setWarning(value)}
          />
        </Group>
        <Group>
          {t('common:days')}
        </Group>
      </Row>}
      <Message warning={warning} min={0} max={10000} />
    </div>
  );
};

export default CourseDeadline;
