import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as R from 'ramda';
import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import * as Alert from '../../../../common/services/alert';
import Container from '../../../../common/components/container';
import Spinner from '../../../../common/components/spinner';
import Placeholder from '../../../../common/components/placeholder';
import ModuleForm from '../../forms/module';
import ContentModule from './content';
import QuizModule from './quiz';
import { ECourseTypes } from '../../definitions';
import * as courseSelector from '../../selectors/courses';
import * as moduleSelector from '../../selectors/module';
import { EPermissions } from '../../../../common/definitions';

class AcademyModule extends React.Component {

  constructor() {
    super();

    this.state = {
      loading: true,
    };

    this.handleSaveForm = this.handleSaveForm.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { history, course, match: { params }, t } = this.props;

    try {
      await this.props.fetchModule(params.moduleId);

      if (!course) this.props.fetchCourse(params.courseId);
    } catch (err) {
      if (err.status_code === 404) {
        Alert.warning(t('learning:module_not_found'));
      } else {
        Alert.error(t('learning:module_error_loading'));
      }

      history.push(`/admin/learning/courses/${params.courseId}`);
    }

    this.setState({ loading: false });
  }

  static props;
  form;

  handleSaveForm() {
    if (!this.form) return;

    try {
      const { wrappedInstance } = this.form;
      wrappedInstance.ref.wrappedInstance.refs.wrapped.handleForeignSubmit();
    } catch (err) {
      console.log('error', err);
    }
  }

  render() {
    const { loading } = this.state;
    const {
      module: moduleItem, course, history, t, ...props
    } = this.props;

    const isLoading = (loading && !moduleItem)
      || (loading && moduleItem && moduleItem.screens && moduleItem.screens.length === 0);

    const ModuleContent = moduleItem && moduleItem.type === 'content'
      ? ContentModule
      : QuizModule;
    const coursePermissions = (course && (course.type === ECourseTypes.ACADEMY
      ? {
        create: EPermissions.ORGANISATION_ACADEMY_COURSES_CREATE,
        update: EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE,
        remove: EPermissions.ORGANISATION_ACADEMY_COURSES_REMOVE,
        view: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW,
        statistics: EPermissions.ORGANISATION_ACADEMY_COURSES_STATISTICS,
      }
      : {
        create: EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE,
        update: EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE,
        remove: EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE,
        view: EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW,
        statistics: EPermissions.ORGANISATION_ONBOARDING_COURSES_STATISTICS,
      })) || {};

    let content = null;

    if (isLoading) {
      content = (
        <Spinner size="large" centered />
      );
    } else if (moduleItem && !moduleItem.name) {
      content = (
        <Placeholder
          icon="build"
          title={t('learning:module_placeholder_title')}
          description={t('learning:module_placeholder_description')}
        />
      );
    } else {
      content = (
        <ModuleContent
          {...R.omit(['tReady', 'i18n'], props)}
          history={history}
          module={moduleItem}
          saveModuleForm={this.handleSaveForm}
          coursePermissions={coursePermissions}
        />
      );
    }

    return (
      <Container name="Module">
        <TopNavigationBar
          className="AcademyModule__TopNavigation"
          breadcrumbs={[
            { name: t('learning:breadcrumb_learning_environment') },
            course && course.type === ECourseTypes.ONBOARDING && {
              name: t('learning:breadcrumb_onboarding'), path: '/admin/learning/onboarding',
            },
            course && course.type === ECourseTypes.ACADEMY && {
              name: t('learning:breadcrumb_academy'), path: '/admin/learning/academy',
            },
            course && { name: course.name, path: `/admin/learning/courses/${course.id}` },
            course && moduleItem && { name: moduleItem.name },
          ]}
        >
          <ModuleForm module={moduleItem} course={course} history={history} />
        </TopNavigationBar>
        <Container.Content>
          {content}
        </Container.Content>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  module: moduleSelector.item(state, props.match.params.moduleId),
  course: courseSelector.item(state, props.match.params.courseId),
});

const mapDispatchToProps = {
  fetchModule: require('../../actions/fetch-module').default,
  fetchCourse: require('../../actions/fetch-course').default,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AcademyModule));
