import Api from '../../../common/services/api';
import { ACADEMY_ADD_MODULE } from './index';

export default (courseId, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: section } = await Api.post(`/v2/organisations/${selected.id}/courses/${courseId}/modules`, {
    type: values.type,
  });

  return dispatch({
    type: ACADEMY_ADD_MODULE,
    courseId,
    section,
    item: section.modules[0],
  });
};
