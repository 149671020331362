import React, { useState, useCallback, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { FormState, InjectedFormProps, reduxForm } from 'redux-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import * as R from 'ramda';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import CoursePhasing from '@modules/learning/components/course-phasing/course-phasing';
import { Row, Group } from '@common/components/form';
import { AudienceInput } from '@common/components/form/audience';
import * as Alert from '@common/services/alert';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import Collapsible from '@common/components/collapsible';
import CoursePreview from '../../components/course-preview';
import ActivateCourseButton from './activate-course-button';

import { EPredicateFields, ESearchPredicateTypes, Predicate, predicatesToPayload } from '@common/utils/predicates';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as coursesSelector from '../../selectors/courses';
import updateCourse from '../../actions/update-course';
import fetchCourseAction from '../../actions/fetch-course';
import { Course } from '@modules/learning/types/objects';
import { Network, OrganisationFunction } from '@common/types/objects';
import { Dispatch, StoreState } from '@common/types/store';

type FormData = {
  initialised: boolean;
  phased: boolean;
  settings: {
    visible_after_days_invited: number | null;
    visibility: {
      predicate_type: string;
      predicates: Array<Object>;
    };
  }
};

type Props = {
  course: Course;
  data: FormState;
  networks: Network[];
  functions: OrganisationFunction[];
};

type OwnProps = InjectedFormProps<FormData> & ConnectedProps<typeof reduxConnector> & Props;

const LearningPublishOnboardingContainer = ({
  course, networks, functions, data,
  submitting, initialize, handleSubmit, fetchCourse,
}: OwnProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams<{ courseId: string }>();
  const [hasPublished, setHasPublished] = useState(false);

  useEffect(() => {
    if (!course) fetchCourse(params.courseId);
  }, []);

  useEffect(() => {
    if (!course) return;
    initialize({
      initialised: true,
      phased: !!course.settings.visible_after_days_invited,
      settings: {
        visibility: {
          predicate_type: course.settings.visibility.predicate_type,
          predicates: course.settings.visibility.predicates.map(
            (predicate: Object, i: number) => R.assoc('id', i, predicate)
          ),
        },
        visible_after_days_invited: course.settings.visible_after_days_invited,
      },
    });
  }, [course]);

  const onHandleSubmit = useCallback(async (values: FormData, dispatch: Dispatch, publishModules = false) => {
    try {
      await dispatch(updateCourse(course.id, {
        published: true,
        settings: {
          visible_after_days_invited: values.phased ? values.settings.visible_after_days_invited : null,
          visibility: {
            predicate_type: values.settings.visibility.predicate_type,
            predicates: predicatesToPayload(values.settings.visibility.predicates as Predicate[]),
          },
        },
        publish_all_modules: !!publishModules
      }));

      setHasPublished(true);
    } catch (error: any) {
      if (error.detail) {
        return Alert.error(error.detail);
      }
      return Alert.forStatus(error.status_code, {
        warning: t('learning:publish_onboarding_warning_publishing'),
        error: t('learning:publish_onboarding_error_publishing'),
        [422]: t('learning:publish_onboarding_warning_publishing_check_content'), // eslint-disable-line no-useless-computed-key
      });
    }
  }, [updateCourse, setHasPublished]);

  const onPopupConfirm = (publishModules: boolean) => {
    return handleSubmit((values, dispatch) => {
      return onHandleSubmit(values, dispatch, publishModules);
    })();
  };

  const formValues = (data && data.values) || {};

  return (
    <Container name="Category">
      {hasPublished && (
        <Modal
          disableRootClose
          className="CoursePublished"
          content={(
            <>
              <img src="/static/images/course-active.svg" alt="Actief" />
              <h2><Trans i18nKey="learning:publish_onboarding_published_title" /></h2>
              <Trans i18nKey="learning:publish_onboarding_published_description" />
              <br />
              <br />
              <Button type="primary" size="large" onClick={() => history.replace('/admin/learning/onboarding')}>
                <Trans i18nKey="learning:publish_onboarding_back_to_onboarding" />
              </Button>
            </>
          )}
        />
      )}
      <TopNavigationBar
        breadcrumbs={[
          { name: t('learning:breadcrumb_learning_environment') },
          { name: t('learning:breadcrumb_onboarding'), path: '/admin/learning/onboarding' },
          course && { name: course.name, path: `/admin/learning/courses/${course.id}` },
        ]}
        title={t('learning:publish_onboarding_publish_for_onboarding')}
        action={(
          <Button onClick={() => history.goBack()} size="large"><Trans i18nKey="learning:publish_onboarding_back" /></Button>
        )}
      />
      <Container.Content>
        <form onSubmit={handleSubmit((values, dispatch) => onHandleSubmit(values, dispatch))}>
          {formValues.initialised && (
            <Collapsible.Group>
              <Collapsible optional id="visiblility" title={t('learning:publish_onboarding_label_visibility')}>
                <Collapsible.Column>
                  <Row>
                    <Group>
                      <AudienceInput
                        name="settings.visibility"
                        enabledFilters={[
                          EPredicateFields.USER,
                          EPredicateFields.LANGUAGE,
                          EPredicateFields.NETWORK,
                          EPredicateFields.FUNCTION,
                        ]}
                      />
                    </Group>
                  </Row>
                </Collapsible.Column>
              </Collapsible>
              <Collapsible
                optional
                id="phasing"
                title={t('learning:publish_onboarding_label_phasing')}
                completed={(formValues.phased && formValues.settings.visible_after_days_invited > 0) ? true : undefined}
              >
                <Collapsible.Column flex={2}>
                  <CoursePhasing phased={formValues.phased} />
                </Collapsible.Column>
                <Collapsible.Column flex={1} />
              </Collapsible>
              <Collapsible
                id="check"
                title={t('learning:publish_onboarding_check_and_publish')}
                completed={false}
              >
                <Collapsible.Column flex={1} />
                <Collapsible.Column flex={1}>
                  <>
                    <CoursePreview
                      course={course}
                      formValues={formValues}
                      networks={networks}
                      functions={functions}
                    />
                    <hr />
                    <ActivateCourseButton
                      isLoading={submitting}
                      courseId={course?.id}
                      orgId={course?.organisation_id}
                      onPopupConfirm={onPopupConfirm}
                    >
                      <Trans i18nKey="learning:publish_onboarding_activate" />
                    </ActivateCourseButton>
                  </>
                </Collapsible.Column>
                <Collapsible.Column flex={1} />
              </Collapsible>
            </Collapsible.Group>
          )}
        </form>
      </Container.Content>
    </Container>
  );
};

const mapStateToProps = (state: StoreState, props: any): Props => ({
  course: coursesSelector.item(state, props.match.params.courseId) as Course,
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
  data: state.form[props.form] || { values: {} },
});
const mapDispatchToProps = {
  fetchCourse: fetchCourseAction,
};
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

const Onboarding = reduxForm<FormData>({
  form: 'course-publish-onboarding',
  initialValues: {
    initialised: false,
    settings: {
      visible_after_days_invited: 1,
      visibility: {
        predicate_type: ESearchPredicateTypes.MATCH_ANY,
        predicates: [],
      },
    },
    phased: false,
  },
})(reduxConnector(LearningPublishOnboardingContainer));

export default Onboarding;
