import Api from '../../../common/services/api';
import { LEARNING_UPDATE_COURSES_ORDER } from './index';

export default (order) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v2/organisations/${selected.id}/courses`, { courses: order });

  return dispatch({
    type: LEARNING_UPDATE_COURSES_ORDER,
    items: order.map((id, index) => ({ id, index })),
  });
};
