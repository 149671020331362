import React, {
  memo, useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';

import Container from '@common/components/container';
import Bar from '@common/components/bar';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import SearchBar from '@common/components/search-bar';
import LearningPathEditorModal from './learning-path-editor-modal';
import { AlertService } from '@common/services/alert';
import Placeholder from '@common/components/placeholder';
import List from '@common/components/list';
import LearningPathListItem from './learning-path-list-item';

import { useAppSelector } from '@common/hooks';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { selected } from '@modules/organisation/selectors/organisation';
import Api from '@common/services/api';
import dndManager from '@common/utils/dnd-manager';

import { ApiLearningPathListItem } from '@modules/learning/types/learning-paths';
import { ApiResponse } from '@common/services/api/types';
import { EPlanPackageConfig } from '@common/definitions';

const LearningPaths = memo(() => {
  const { t } = useTranslation();
  const itemsRef = useRef<ApiLearningPathListItem[] | null>(null);
  const orgId = useAppSelector(selected).id;
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ApiLearningPathListItem[]>([]);

  const {
    isAvailable: canCreateLearningPaths, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_LEARNING_PATHS);

  useEffect(() => {
    setLoading(true);
    const url = `/v2/organisations/${orgId}/learning-paths`;
    Api.get<ApiResponse<ApiLearningPathListItem[]>>(url).then((res) => {
      setItems(res.data);
      itemsRef.current = res.data || [];
      setLoading(false);
    }).catch((error) => {
      AlertService.error(t('common:something_went_wrong'));
      throw error;
    });
  }, [orgId, setItems, setLoading, itemsRef]);

  const history = useHistory();
  const { url } = useRouteMatch();
  const [creating, setCreating] = useState<boolean>(false);
  const onEditorHide = useCallback((pathCreated) => {
    if (pathCreated) {
      history.push(`${url}/${pathCreated.data.id}`);
    } else {
      setCreating(false);
    }
  }, [setCreating, url, history]);

  const [search, setSearch] = useState<null | string>(null);

  const filteredItems = useMemo(() => {
    if (!items) return [];
    if (!search) return items;
    const searchTerm = search.toLowerCase();
    return items.filter(({ name }) => name.toLowerCase().indexOf(searchTerm) > -1);
  }, [search, items]);

  const resetOrder = useCallback(() => {
    if (!itemsRef.current) return;
    setItems(itemsRef.current);
  }, [setItems, itemsRef]);

  const onLearningPathDrop = useCallback(async () => {
    try {
      if (!items) return;
      const newOrder = items.map((c) => c.id);
      const prevOrder = itemsRef.current!.map((c) => c.id);
      if (JSON.stringify(newOrder) === JSON.stringify(prevOrder)) {
        return;
      }
      const coursesUrl = `/v2/organisations/${orgId}/learning-paths`;
      const payload = { learning_paths: items.map((c) => c.id) };
      await Api.patch(coursesUrl, payload);
      itemsRef.current = items;
      AlertService.success(t('learning:saved_order'));
    } catch (error) {
      AlertService.error(t('learning:error_saving_order'));
      resetOrder();
      throw error;
    }
  }, [itemsRef, items, orgId, t, resetOrder]);

  const onLearningPathOrderChange = useCallback((
    sourceId: string,
    targetId: string
  ) => {
    setItems((prevItems) => {
      if (!prevItems) return prevItems;

      const sourcePath = prevItems.find((c) => c.id === sourceId);
      if (!sourcePath) return prevItems;

      const targetIndex = prevItems.findIndex((c) => c.id === targetId);
      const newItems = prevItems.filter((c) => c.id !== sourceId);
      newItems.splice(targetIndex, 0, sourcePath);

      return newItems;
    });
  }, [setItems]);

  const onCreateHandler = useCallback(() => {
    if (canCreateLearningPaths) {
      setCreating(true);
    } else {
      showUpgradeModal();
    }
  }, [canCreateLearningPaths, setCreating, showUpgradeModal]);

  return (
    <Container.Content>
      <Bar>
        <h2 className="pull-left">
          <Trans i18nKey="learning:learning_paths" />
        </h2>
        <div className="pull-right">
          <Button
            size="large"
            type="primary"
            iconRight="add"
            onClick={onCreateHandler}
          >
            <Trans i18nKey="learning:create_learning_path" />
          </Button>
        </div>
      </Bar>
      {
        creating && (
          <LearningPathEditorModal onHide={onEditorHide} orgId={orgId} />
        )
      }
      {
        loading ?
          <Spinner centered size="large" /> :
          (
            <>
              {
                items.length > 0 && (
                  <Bar>
                    <SearchBar
                      onSearch={setSearch}
                      debounce={false}
                      filter={false}
                      placeholder={t('learning:search_learning_path')}
                    />
                  </Bar>
                )
              }
              <DndProvider manager={dndManager}>
                <List
                  className="OnboardingCourses"
                  items={filteredItems}
                  renderRow={LearningPathListItem}
                  rowProps={{
                    orgId,
                    setLearningPaths: setItems,
                    onDrop: onLearningPathDrop,
                    onChangeOrder: onLearningPathOrderChange,
                    resetOrder
                  }}
                  placeholder={
                    items?.length > 0 ? (
                      <Placeholder title={t('common:no_results')} />
                    ) : (
                      <Placeholder
                        image="/static/images/courses-placeholder.svg"
                        title={t('learning:add_learning_path')}
                        action={(
                          <Button
                            iconRight="add"
                            type="primary"
                            onClick={onCreateHandler}
                          >
                            <Trans i18nKey="learning:create_learning_path" />
                          </Button>
                        )}
                      />
                    )
                  }
                />
              </DndProvider>
            </>
          )
      }
    </Container.Content>
  );
});

export default LearningPaths;
