import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { ACADEMY_ADD_SCREEN } from '@modules/learning/actions';
import { formatScreenToAdd } from '@modules/learning/actions/add-screen';
import { useDispatch } from 'react-redux';
import DuplicateDeleteDropdown from '@common/components/dropdown/duplicate-delete-dropdown';

type ScreenDropdownProps = {
  deleteModalTitle?: string;
  organisationId: string;
  moduleId: string;
  screen: any;
  handleDuplicateScreen: (...args: any[]) => void;
  onRemove: (...args: any[]) => void;
};

const ScreenDropdown = memo(({
  organisationId,
  moduleId,
  screen,
  handleDuplicateScreen,
  onRemove,
  deleteModalTitle
}: ScreenDropdownProps) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screenId = screen.id;

  const duplicate = useCallback(async () => {
    const moduleUrl = `/v2/organisations/${organisationId}/modules/${moduleId}`;
    const url = `${moduleUrl}/screens/${screenId}/duplicate`;
    try {
      const { data: duplicatedScreen } = await Api.post(url, {});
      const action = {
        type: ACADEMY_ADD_SCREEN,
        moduleId,
        screen: formatScreenToAdd(duplicatedScreen),
      };
      // console.log("debug action", action);
      dispatch(action);
      return duplicatedScreen;
    } catch (error: any) {
      console.log('error', error);
      AlertService.error(t('core:changes_could_not_be_saved'));
    }
  }, [organisationId, moduleId, screenId, screen, dispatch]);

  const onDuplicateClick = useCallback(() => {
    handleDuplicateScreen(duplicate);
  }, [handleDuplicateScreen, duplicate]);

  return (
    <DuplicateDeleteDropdown
      deleteModalTitle={deleteModalTitle}
      onDelete={onRemove}
      onDuplicate={onDuplicateClick}
    />
  );
});

export default ScreenDropdown;
