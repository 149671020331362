import * as React from 'react';
import Icon from '../icon';

const ToggleContent = ({
  children, title, description, image, disabled, action, handleRef, previewRef, className,
}) => {
  const [extended, setExtended] = React.useState(!disabled);

  const classNames = ['ToggleContent'];
  if (extended) classNames.push('ToggleContent--extended');
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      <div className="ToggleContent__Header" ref={previewRef}>
        {!disabled && (
          <div
            className="ToggleContent__Header__Toggle"
            onClick={() => setExtended(!extended)}
          >
            <Icon type={extended ? 'expand_less' : 'expand_more'} />
          </div>
        )}
        {handleRef && (
          <div className="ToggleContent__Header__Drag" ref={handleRef}>
            <Icon type="drag_handle" />
          </div>
        )}
        <div className="ToggleContent__Header__Info">
          {image}
          {title}
          {description && <small>{description}</small>}
        </div>
        {action}
      </div>
      {extended && (
        <div className="ToggleContent__Container">
          {children}
        </div>
      )}
    </div>
  );
};

export default ToggleContent;
