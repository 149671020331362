import { Api } from '@services/api';
import { LEARNING_FETCH_COURSES } from './index';

export default () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data, meta } = await Api.get(`/v2/organisations/${selected.id}/courses?include=users`);

  return dispatch({
    type: LEARNING_FETCH_COURSES,
    items: data,
    related: meta.related,
  });
};
