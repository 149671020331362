import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import ImageInput from '@common/components/form/inputs/image';
import TextareaInput from '@common/components/form/inputs/textarea';
import { Row, Group, Label, TextInput } from '@common/components/form';
import * as alert from '@common/services/alert';
import Modal from '@common/components/modal';
import createCourse from '../../actions/add-course';
import updateCourse from '../../actions/update-course';

import './course-form.scss';

const getFileName = (file) => {
  if (!file || file.path === null) return null;

  const parts = (file.path || file).split(file.default ? 'attachments/' : '/');

  return parts[parts.length - 1];
};

class CourseForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show || false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { course } = this.props;
    if (course) this.handleInitialize(course);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.course && nextProps.course) this.handleInitialize(nextProps.course);
  }

  static props;

  handleInitialize = (course) => {
    this.props.initialize({
      name: course.name,
      description: course.description,
      header_image: { path: course.header_image_url },
    });
  };

  async handleSubmit(values, dispatch) {
    const { type, course, history, t } = this.props;

    try {
      const payload = {
        name: values.name,
        description: values.description,
      };

      // Change image or remove it, but don't change when it's
      // a object with path (could be placeholder)
      if (values.header_image && values.header_image.type)
        payload.header_image_url = getFileName(values.header_image.path);
      if (values.header_image === null) payload.header_image_url = null;

      if (!course) {
        const { item } = await dispatch(createCourse(type, payload));

        alert.success(t('learning:form_course_created'));
        history.replace(`/admin/learning/courses/${item.id}`);
      } else {
        await dispatch(updateCourse(course.id, payload));

        alert.success(t('learning:form_course_edited'));
        if (this.state.show) this.setState({ show: false });
      }
    } catch (response) {
      return alert.forStatus(response.status_code, {
        warning: t('learning:form_course_warning_saving_course'),
        error: t('learning:form_course_error_saving_course'),
      });
    }
  }

  render() {
    const { removing } = this.state;
    const {
      children, course, invalid, submitting, handleSubmit, history, t,
    } = this.props;

    return (
      <Modal
        list
        show={this.state.show}
        disableRootClose={!course}
        onClose={!course && (() => history.goBack())}
        onShow={() => this.setState({ show: true })}
        size="small"
        title={t('learning:form_course_modal_title')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(this.handleSubmit),
        }}
        content={(
          <>
            <Row>
              <Group>
                <Label text={t('learning:form_course_header_image_label')} />
                <ImageInput
                  processFile
                  preview
                  unsplash
                  aspectRatio={3 / 2}
                  name="header_image"
                  acceptedExtLabel="JPG, PNG, GIF"
                />
              </Group>
            </Row>
            <Row>
              <Group>
                <Label forInput="name" text={t('learning:form_course_name_label')} maxLength={30} />
                <TextInput name="name" placeholder={t('learning:form_course_name_placeholder')} maxLength={30} />
              </Group>
            </Row>
            <Row>
              <Group>
                <Label forInput="description" text={t('learning:form_course_description_label')} />
                <TextareaInput name="description" />
              </Group>
            </Row>
          </>
        )}
        footer={(
          <Button
            type="primary"
            buttonType="submit"
            disabled={invalid || removing}
            isLoading={submitting}
          >
            {course ? t('learning:form_course_edit') : t('learning:form_course_create')}
          </Button>
        )}
      >
        {children}
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => ({
  form: state.form[props.form] || { values: {} },
});

export default withTranslation()(reduxForm({
  form: 'course',
  initialValues: {
    name: '',
    description: '',
    header_image: null,
  },
  validate: require('../validators/category').default,
})(connect(mapStateToProps)(CourseForm)));
