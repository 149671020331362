import Api from '../../../common/services/api';
import { ACADEMY_REMOVE_SCREEN } from './index';

export default (id, moduleId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v2/organisations/${selected.id}/modules/${moduleId}/screens/${id}`);

  return dispatch({
    type: ACADEMY_REMOVE_SCREEN,
    id,
    moduleId,
  });
};
